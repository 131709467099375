@keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, 0, 1px);
    transform: translate3d(0, 0, 1px)
  }
  50% {
    color: #90cafe;
    -webkit-transform: translate3d(0, 10px, 1px);
    transform: translate3d(0, 10px, 1px)
  }
  100% {
    -webkit-transform: translate3d(0, 0, 1px);
    transform: translate3d(0, 0, 1px)
  }
}
.slot {
  i{
    display: block;
  }
  .ld{
    width: 100%;
    div{
      width: 60px;
      height: 60px;
      margin: 30px auto;
      text-align: center;
      line-height: 40px;
      border-radius: 50%;
      background: #fff;
      font-weight: 600;
      color: #999;
      cursor: pointer;
      font-size: 30px;
      box-shadow: rgba(10, 148, 227, 0.8) 0 2px 18px -8px;
      opacity: .6;
      transition: .1s ease-in-out;
      &:hover{
        opacity: 1;
      }
    }
    .act{
      opacity: 1;
      animation: bounce 1s infinite ease-in-out;
    }
  }
  .ls{
    display: flex;
    flex-wrap: wrap;
    min-height: 400px;
    position: relative;
  }
  .fk{
    position: absolute;
    right: 166px;
    color: #fd7c00;
    font-weight: bold;
    font-family: 'xDigital', serif;
    font-size: 38px;
    top: 38px;
  }
  .ff{
    margin-top: 60px;
    padding: 20px;
  }
  .f0{
    display: flex;
    align-items: center;
    height: 66px;
    border-top: 1px solid rgba(0, 0, 0, 0.04);
    font-size: 13px;
    color: #666;
    padding:  0 20px;
    label{
      width: 90px;
      display: block;
    }
    div{
      cursor: pointer;
      margin: 3px;
      padding: 0 20px;
      line-height: 35px;
      height: 35px;
      border-radius: 4px;
      &.act,&:hover{
        color: #fff;
        background: linear-gradient(#6dbbfe,#6a99ff);
      }
    }
  }
  .sh{
    margin: 10px 0 0;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0 5px 30px -15px;
    position: relative;
    min-height: 146px;
    border-radius: 10px;
    .ft{
      position: relative;
    }
    .search{
      width: 301px;
      display: flex;
      border: 1px solid rgba(0, 0, 0, 0.07);
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      top: 34px;
      left: 50px;
      input{
        background: rgba(255, 255, 255, 0.5);
        flex: 1;
        border: none;
        display: block;
        height: 39px;
        padding:  0 20px 0 10px;
        outline: none;
        box-shadow: none;
      }
      span{
        right: 80px;
        width: 20px;
        height: 39px;
        text-align: center;
        line-height: 39px;
        font-size: 12px;
        cursor: pointer;
        opacity: .5;
        top: 10px;
        &:hover{
          opacity: 1;
        }
      }
      i{
        cursor: pointer;
        transition: .2s ease-in-out;
        background: #fd7c00  url("../../assets/images/new/sc.png") no-repeat center;
        background-size: 30%;
        width: 74px;
        height: 39px;
        &:hover{
          opacity: .8;
        }
      }
    }
    &:before{
      content: '';
      display: block;
      position: absolute;
      left: -18px;
      right: -16px;
      top: 0;
      bottom: 0;
      background: url(../../assets/images/new/sh.png) top left no-repeat;
    }
  }
  .s-card{
    width: 297px;
    height: 196px;
    margin: 20px 17px 0 0;
    display: flex;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    transition: .2s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 20px -10px;
    &:hover{
      box-shadow: rgba(52, 126, 242, 0.6) 0 8px 24px -10px;
      .msk{
        transform: translateY(0);
      }
    }
    label{
      font-weight: 600;
      color: #333;
      font-size: 14px;
      margin-top: 14px;
    }
    i{
      display: block;
      background: center none;
      background-size: cover;
      width: 126px;
      height: 126px;
      border-radius: 8px;
    }
    &:nth-child(4n){
      margin-right: 0;
    }
  }
  .full{
    padding: 20px 0;
  }
  .game-cate{
    .s-warp{
      div{
        flex: none;
      }
      i{
        width: 100%;
        height: 60px;
        background-image: url(../../assets/images/new/icon/s1.png) ;
      }
      [class^='i-']{
        min-width: 120px;
        &:hover,&.act{
          &:not(.i-all) i{
            background-image: url(../../assets/images/new/icon/s2.png)
          }
        }
      }
    }

    .i-p12 i{
      background-position: -2.8% 0
    }
    .i-p2 i{
      background-position: 9% 0
    }
    .i-p11 i{
      background-position: 21.8% 0
    }
    .i-p4{
      i{
        background: #eee!important;
        width: 60px;
      }
    }
    .i-p9  i{
      background-position: 33.1% 0
    }
    .i-p10 {
      width: 180px;
      i{
        background-position: 45% 0
      }
    }
    .i-p3 i{
      background-position: 58.6% 0
    }
    .i-p6 i{
      background-position: 71.5% 0
    }
    .i-p8 {
      width: 140px;
      i{
        background-position: 84% 0
      }
    }
    .i-p1 {
      width: 180px;
      i{
        background-position: 103% 0
      }
    }
    .i-p25 {
      width: 160px;
      i{
        background-position: 33% -116px
      }
    }
    .i-p29 {
      i{
        background-position: 45% -116px
      }
    }
  }
}
