.trend-ct-scroll{
  position: relative;
  width: 98%;
  margin: 3px auto 20px;
}
.trend-chart {
  user-select: none;
  max-height: 720px;
  flex: 1;
  background: #fff;
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  cursor: grab;
  &:active{
    cursor: grabbing;
  }
  .num {
    color: #0d9fff;
  }
  .blu1{
    color: #0d9fff;
  }
  .act, .act1, .act2, .act3, .blu, .red{
    padding: 0;
    border-radius: 50%;
    width: 20px;
    height: 20px;
  }

  &.trend {
   .fz-ctx{
     .act, .act1, .act2, .act3, .blu, .red {
       color: #fff;
       background: #fd4e5a;
     }

     .act1 {
       background: #8bc34a;
     }

     .act2 {
       background: #bd3ed4;
     }

     .act3 {
       background: #fd7700;
     }

     .blu, .red {
       color: #fff;
       background: #00a2ff;
     }

     .gre {
       color: #34bc0f;
     }

     .red {
       background: #f53f00;
     }
   }
  }

  &.hot {
   .fz-ctx{
     .h0 {
       background: #377ef6;
       color: #fff;
     }

     .h1 {
       background: #ffcf00;
       color: #000;
     }
   }
  }

  .num {
    padding: 0 10px;
  }

  .fz-hd, .fz-ft {
    background: #afb5d9;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 3px;
    z-index: 3;

    .wrp {
      div {
        padding: 0 5px;
        outline: 1px solid rgba(255, 255, 255, 0.2);
      }
    }
  }

  .fz-left {
    border-right: 1px dashed rgba(10, 10, 10, 0.36);

    .ro:first-child {
      .wrp div {
        padding: 0 10px;
      }
    }
  }

  &.line {
    .fz-right {
      canvas {
        visibility: visible;
      }
    }
  }

  .fz-right {
    canvas {
      pointer-events: none;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
      visibility: hidden;
    }

    flex: 1;

    .fz-ctx {
      position: relative;

      .wrp {
        .ms {
          background: rgba(58, 183, 255, 0.15);
        }

        span.hi{
          opacity: 0;
        }
      }
    }
  }

  &.miss {
    .fz-ctx {
      .wrp {
        span.hi {
          opacity: 1;
        }
      }
    }
  }

  .fz {
    flex: 1;
    overflow: hidden;
    transform: translate3d(0, 0, 0);

    & > div {
      position: relative;
      transform: translate3d(0, 0, 0);
    }
  }

  div {
    //pointer-events: none;
    user-select: none;
    display: flex;
  }
  .ro{
    flex-grow: 1;
  }
  .ro + .ro {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
  }

  .ro, .fz-ctx, .fz-ft, .fz-hd {
    flex-direction: column;
  }

  .fz-ctx {
    flex: 1;
    .wrp {
      &:nth-child(2n) {
        background: rgba(103, 127, 134, 0.05);
      }

      div:nth-child(2n) {
        background: rgba(103, 127, 134, 0.02);
      }
    }
  }

  .wrp {
    flex: 1;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    div {
      flex: 1;
      min-width: 30px;
      padding: 0 5px;
      white-space: nowrap;
      align-items: center;
      justify-content: center;
    }
  }
}
