.c-pvp {
  background: #0c0401;
   box-shadow: inset rgba(42,21,0 ,.3) 0 0 200px;
  .b {
    position: absolute;
    left: 0;
    right: 0;
    background: url("./img/0.jpg") center top no-repeat;
    background-size: contain;
    padding-top: 31.1%;
  }

  .bx {
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, .2) 0 5px 20px, inset rgba(0, 0, 0, .4) 0 0 100px;
    width: 1240px;
    max-width: 90%;
    margin: 30% auto 40px;
    background: linear-gradient(#221909, #281c09);
    height: 513px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  i {
    display: block;
    position: absolute;
    width: 145px;
    height: 145px;
    border: 1px solid;
  }

  .i0 {
    left: 0;
    top: 0;
    border-color: #c0a64f;
    border-right: 0;
    border-bottom: 0;
  }

  .i1 {
    right: 0;
    bottom: 0;
    border-color: #b9b9b9;
    border-left: 0;
    border-top: 0;
  }

  .b1 {
    width: 80%;
    height: 421px;
    position: relative;
    .ls{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      div {
        position: relative;
        cursor: pointer;
        width: 288px;
        height: 373px;
        &:after {
          transition: 1s ease-out;
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          box-shadow: rgba(0, 0, 0, .1) 0 3px 6px;
        }

        &:hover {
          &:after {
            transform: rotateY(360deg);
            box-shadow: rgba(0, 0, 0, .2) 0 5px 13px;
          }
        }
      }
      .pvp-0 {
        &:after {
          background: url("./img/1.jpg");
        }
      }
      .pvp-3 {
        &:after {
          background: url("./img/3.png");
          height: 381px;
        }
      }

      .pvp-1 {
        &:after {
          background: url("./img/0.png");
          height: 408px;
        }
      }
    }
  }
}
