.full-page{
  .full.elect{
    transition: .5s ease-in-out;
    background: #131927 url(./img/b.jpg) center  no-repeat;
    box-sizing: content-box;
    margin: -1px 0;
    background-size: cover;
    flex: 1;
    min-height: 777px;
    position: relative;
    .ls{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 1024px;
      margin: 500px auto 30px;
    }
  }
  .elect-card{
    &:hover:after{
      transform: scale(1.1);
      transform-origin: center bottom;
    }
    cursor: pointer;
    width: 305px;
    height: 468px;
    &:after{
      transition: .2s ease-in-out;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: url("./img/im.png") center bottom no-repeat;
    }
    &.lh:after{
      background-image: url("./img/lh.png");
    }
    &.ob:after{
      background-image: url("./img/ob.png");
    }
  }
}
