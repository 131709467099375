.welfarePage{
.x-sc {
  .r{
    justify-content: space-between;
    padding:  10px 30px;
    p{
      color: #fd7373;
      height: 24px;
      border-radius: 5px;
      background: #fce8e8;
      display: flex;
      align-items: center;
      justify-content: center;
      padding:  0 10px;
    }
    a{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 116px;
      height: 31px;
      border-radius: 8px;
      border: solid 1px #dae4f1;
      background: #ffffff;
      color: #60a7f4;
    }
  }
}
}
