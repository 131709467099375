.pz-msk {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, .5);
  transition: .3s;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  z-index: -1;

  &.act {
    z-index: 25;
    opacity: 1;
    pointer-events: all;
  }

  .cd {
    width: 337px;
    min-height: 200px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, .2) 0 10px 30px;
    display: flex;
    flex-direction: column;

    .ti {
      height: 61px;
      display: flex;
      align-items: center;
      padding: 0 20px;
      background: linear-gradient(#efefef, #e9e9e9);
      border-bottom: 1px solid #e7e7e7;
    }

    .ct {
      padding: 20px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .bn {
        width: 203px;
        height: 41px;
        border-radius: 6px;
        background: linear-gradient(#de0219, #901719);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-weight: 600;
        letter-spacing: 3px;
        margin-top: 20px;
        cursor: pointer;

        &:hover {
          opacity: .9;
        }
      }

      .c {
        color: #db202a;
        font-size: 22px;
        margin: 10px 0;

        b {
          font-size: 30px;
        }
      }
    }
  }
}

.g-cir-o {
  position: absolute;
  left: 5%;
  right: 5%;
  bottom: 5%;
  top: 5%;
  border-radius: 50%;
  overflow: hidden;
  background: #262629;
  z-index: 2;

  .g-cir {
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, .2);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .g-dt {
    &.act {
      z-index: 5;
      border: none;
      background: linear-gradient(-45deg,transparent,red,transparent);
      opacity: .2;
    }
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 50%;
    border: inherit;
    transform-origin: right bottom !important;
  }
  .g-ti {
    b {
      font-weight: 600;
      font-size: 15px;
    }
  }

  .g-ls {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .g-ft {
    z-index: 100;
    position: absolute;
    color: #fee8a7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transform-origin: 0 0 !important;
    font-size: 13px;

    i {
      padding-top: 60%;
      width: 100%;
      border-radius: 5px;
      margin-bottom: 5px;
      background: url("./img/g0.png") center no-repeat;
      background-size: contain;
    }
  }
}

.act-0 {
  background: #000;
  flex-direction: column;

  .crt {
    color: #987358;
    background: -webkit-linear-gradient(0deg, #eac68c, #987358);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .tms {
    margin: 0 auto 20px;
    height: 100px;
    width: 611px;
    border-radius: 8px;
    background: linear-gradient(#938263, #635144);
    color: #fff;
    display: flex;
    padding: 7px;
    font-weight: 200;

    & > span {
      width: 62px;
      font-size: 30px;
      display: block;
    }

    .lst {
      box-shadow: rgba(0, 0, 0, .2) 0 6px 14px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      font-size: 27px;
      padding: 0 20px;
      flex: 1;
      background: #151228;

      b {
        padding: 0 30px;
        font-style: italic;
        font-size: 35px;
      }
    }
  }

  .tp {
    font-weight: 200;
    font-size: 23px;
    width: 611px;
    height: 60px;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 30px;
    margin: 20px auto;
    background: #2c323d;
    border-radius: 4px;

    i {
      display: block;
      background: url("./img/7.png") center no-repeat;
      width: 30px;
      height: 32px;
      margin-right: 10px;
    }
  }

  .ru {
    margin: 45px auto 0;
    width: 984px;
    background: #1d2127;
    padding: 30px;
    color: #fff;
    border-radius: 4px;

    * {
      line-height: 2;
    }

    h1 {
      font-size: 20px
    }

    p {
      font-size: 16px;
      white-space: pre-wrap;
    }

    span {
      color: red;
    }
  }

  i {
    display: block;
  }

  .xt {
    max-width: 1240px;
    margin: 0 auto;
    min-width: 900px;
    width: 100%;
  }

  .h {
    background: linear-gradient(#000, transparent);

    i {
      background: url("./img/0.png");
      width: 170px;
      height: 129px;
    }
  }

  .p0 {
    position: relative;
    background: url("./img/0.jpg") no-repeat bottom center;
    background-size: cover;
    height: 1076px;
  }

  .p1 {
    background: url("./img/1.jpg") no-repeat top center;
    background-size: cover;
    height: 1159px;
  }

  .t1 {
    background: url("./img/3.png");
    width: 613px;
    height: 317px;
    margin: 10px auto 0;
    position: relative;
    z-index: 3;
  }

  .tb {
    top: 318px;
    width: 676px;
    position: absolute;
    height: 181px;
    left: 50%;
    transform: translateX(-50%);
  }

  .t2, .t3, .t4, .t5 {
    position: absolute;
    bottom: 0;
    background: url("./img/2.png");
    width: 96px;
    height: 97px;
    left: 0;
  }

  .t4, .t5 {
    background: url("./img/1.png");
    width: 115px;
    height: 116px;
    margin: 10px;
  }

  .t2, .t4 {
    transform: rotateY(180deg);
    left: auto;
    right: 0;
  }

  .y {
    height: 14px;
    background: linear-gradient(90deg, transparent, #d4af51 10%, #d4af51 90%, transparent);
  }

  .ca {
    width: 580px;
    height: 580px;
    position: relative;
    margin: 0 auto 50px;

    .x-loading {
      z-index: 10;
      border-radius: 50%;
      background: rgba(50, 50, 50, 0.5) !important;
    }

    .in {
      border-radius: 50%;
      width: 200px;
      height: 200px;
      top: 0;
      background: rgba(0, 0, 0, .3);
      color: #fff;
    }
  }

  .c1 {
    background: #1f1f20 url("./img/5.png");
    position: absolute;
    width: 580px;
    height: 580px;
    left: 0;
    top: 0;
    border-radius: 50%;
  }

  .c0 {
    &:hover {
      opacity: 1;
    }

    opacity: .9;
    cursor: pointer;
    z-index: 5;
    background: rgba(0, 0, 0, .3) url("./img/4.png") no-repeat top center;
    border-radius: 50%;
    width: 238px;
    height: 238px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  .gs {
    background: url("./img/6.png");
  }

  .prz {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    b {
      font-size: 50px;
      letter-spacing: 2px;
    }

    i {
      font-size: 30px;
      padding: 0 20px;
    }

    span {
      font-size: 18px;
    }
  }

  .pz-ti {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 18px 0;

    span {
      font-size: 24px;
      padding: 0 10px;
    }

    & > div {
      display: flex;

      i {
        background: linear-gradient(-45deg, #b8a274, #82654e);
        display: block;
        width: 22px;
        height: 22px;
        margin: 0 10px;
        transform: rotate(-45deg);
        opacity: .4;

        & + i {
          opacity: .7;

          & + i {
            opacity: 1;
          }
        }
      }
    }

    .r {
      flex-direction: row-reverse;
    }
  }

  .pz-ls {
    position: relative;
    width: 658px;
    margin: 30px auto;
    height: 300px;
    overflow: hidden;
    padding: 0 10px;

    .pl {
      position: relative;
      height: 100px;
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 22px;
      padding: 0 20px;
      overflow: hidden;

      &.ani {
        transition: .6s ease-out;
      }

      &.hi {
        margin-top: -100px;
      }

      &:after, &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        height: 1px;
        background: linear-gradient(90deg, transparent, #7e6e4f, transparent);
        opacity: .5;
      }

      &:after {
        top: 0
      }

      &:before {
        bottom: 0;
      }

      s {
        width: 100%;
        height: 100%;
        display: block;
        background-position: center !important;
        background-size: contain;
      }

      .a {
        i {
          background: url("./img/8.png") no-repeat center;
          width: 30px;
          height: 28px;
          position: absolute;
          left: -10px;
          top: -10px;
        }

        position: relative;
        height: 69px;
        width: 69px;
        margin-right: 20px;
        background: #fff;
        border-radius: 50%;
        border: 3px solid #f87339;
      }

      & > span {
        flex: 1;
      }

      .pv {
        letter-spacing: 2px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        b {
          font-size: 34px;
          flex: 1;
          text-align: right;
          color: #ffa310;
        }
      }
    }
  }
}
