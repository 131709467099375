@import "../../styles/util";

.trend-h,.trend-ctx{
  background: #fff;
  width: 1240px;
  margin: 10px auto;
  padding: 20px;
  border-radius: 3px;
  border: 1px solid #f2f2f2;
  box-shadow: rgba(0, 0, 0, 0.1) 0 5px 20px -10px;
}


$w: 35px;
.trend-page {
  &:before, &:after {
    content: none;
  }
  input{
    margin-right: 3px;
  }
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.trend-m1 {
  display: flex;
  flex-wrap: wrap;
  height: 80px;
  padding-top: 15px;

  div {
    height: 32px;
    font-size: 13px;
    min-width: 90px;
    padding: 0 10px;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
    border-radius: 3px;

    &:hover {
      color: #0d9fff;
    }
  }

  .act {
    color: #0d9fff;
  }
}

.trend-head {
  display: flex;

  div {
    height: 40px;
    margin-right: 10px;
    line-height: 40px;
    padding: 0 30px;
    cursor: pointer;
    border: 1px solid #f2f2f2;
    border-radius: 30px;
  }

  .act {
    background: linear-gradient(90deg, #1ea5fd, #0095fc);
    box-shadow: rgba(0, 96, 255, 0.3) 0 2px 10px -4px;
    color: #fff;
  }

  .cur {
    border-color: #0d9fff;
  }
}

.td-sub-head {
  display: flex;
  align-items: center;
  padding: 20px 20px 0;

  div {
    margin: 0 3px;
    cursor: pointer;
    padding: 0 15px;
    min-width: 70px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    border-radius: 30px;

    &:hover {
      background: #eee;
    }

    &.act {
      color: #fff;
      background: linear-gradient(90deg, #1ea5fd, #0095fc);
      box-shadow: rgba(0, 96, 255, 0.3) 0 2px 10px -4px;
    }
  }
}

.td-sub-head1 {
  width: 100%;
  max-width: 1570px;
  min-width: 1240px;
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 10px;

  div {
    height: 30px;
    line-height: 30px;
    margin-right: 10px;
    text-align: center;
    min-width: 60px;
    border-radius: 2px;
    font-size: 13px;
    cursor: pointer;

    &.act {
      color: #fff;
      background: #0d9fff;
      border-radius: 20px;
    }
  }

  .c-btn {
    border-radius: 3px;
    padding: 0 15px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.k3 {
  .trend-ctx {
    & > .tb {
      & > tbody {
        & > tr {
          & > td:nth-child(2) {
            table {
              width: 300px !important;

              .num {
                letter-spacing: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.trend-ctx {
  .tbx {
    overflow: auto;
    flex: 1;
  }
  .lts {
    margin-top: 5px;
    text-align: left;
    font-size: 17px;
    padding: 11px 20px;
    border-bottom: 1px solid #ececec;
  }
  flex: 1;
  display: flex;
  flex-direction: column;
  .r {
    background: chUrl("un-chart.png") -100px 0 no-repeat;
    height: 12px;
    width: 18px;
    text-align: center;
    font-style: normal;
  }
}
