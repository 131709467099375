@import "../../styles/util";
@import "../../styles/anim";

$hoverLeftColor: #448af5;
.dashboard {
  position: relative;
  background: #eeeff5 url(../../assets/images/proxy/bg_user_center.png) no-repeat 50% -20px;
  background-size: cover;
  padding-bottom: 20px;
  padding-top: 1px;

  .r {
    display: flex;
    align-items: center;
  }

  .ma {
    background: #f5f5f5;
    padding: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
  }

  &:after {
    content: '';
    clear: both;
    display: block;
  }

  .d-container {
    padding: 20px;
    width: 1240px;
    min-height: 718px;
    margin: 0 auto;
    position: relative;
    overflow: visible;
    border-radius: 12px;
    display: flex;
    background-size: cover;

    & > div {
      min-height: 718px;
    }
  }

  .mu-l {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    width: 188px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, .2) 0 4px 14px -8px;

    .hd {
      background: #f0f1f7;
      border-bottom: 1px solid #dae4f1;
    }

    .mx {
      border-bottom: 1px solid #dae4f1;
      display: flex;

      span {
        font-size: 13px;
        flex: 1;
        height: 35px;
        line-height: 35px;
        text-align: center;
        cursor: pointer;

        & + span {
          border-left: 1px solid #dae4f1;
        }

        &:hover {
          background: rgba(226, 234, 245, 0.5);
        }
      }
    }

    .wall {
      font-size: 13px;
      padding-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      .w {
        margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      i, s {
        display: block;
        margin: 0 5px;
        background: url("./img/0.png") center no-repeat;
        width: 13px;
        height: 13px;
      }

      s {
        background-image: url("./img/1.png");
        cursor: pointer;
        opacity: .6;

        &:hover {
          opacity: 1;
        }

        &.ld {
          pointer-events: none;
          animation: rotateZ360 .6s linear both infinite;
        }
      }
    }
  }

  .main-page {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .mu-r {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 15px;
  }

  .mu_span {
    white-space: nowrap;
    display: block;
    line-height: 46px;
    border-radius: 6px;
  }

  .slide-mu-r {
    border-radius: 10px;
    position: absolute;
    left: 180px;
    top: 0;
    bottom: 0;
    width: 160px;
    z-index: 99;
    background: rgba(255, 255, 255, 0.85);
    padding: 20px 0 !important;
    box-shadow: rgba(0, 0, 0, 0.5) 6px 4px 28px -14px;
    display: none;

    .mu_span {
      padding: 0 30px;
    }
  }

  .actUl {
    display: block !important;
  }


  .us {
    height: 204px;
    background: #fff;
    margin-bottom: 10px;
    position: relative;

    label {
      text-align: center;
      display: block;
      font-size: 13px;
      padding: 0 0 20px;

      b {
        font-weight: 400;
      }

      .re {
        color: red;
      }

      .ro {
        color: orange;
      }

      .gr {
        color: #0c9264;
      }
    }

    .ics {
      display: flex;
      margin: 0 auto;
      width: 110px;
      justify-content: space-between;
      padding-bottom: 18px;

      i {
        height: 20px;
        width: 20px;
        display: flex;
        background: #dee6ec center no-repeat;
        background-size: 70% auto;
        border-radius: 50%;
        position: relative;
        font-style: normal;
        div {
          position: absolute;
          display: none;
          white-space: nowrap;
          padding: 0 20px;
          width: auto;
          height: 32px;
          background: rgba(163, 166, 168, 0.6);
          box-shadow: 4px 3px 8px -2px rgba(136, 136, 136, 0.3);
          border-radius: 6px;
          color: #fff;
          font-size: 13px;
        }

        &:hover {
          div {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translate3d(-50%, 10px, 0);

            &:before {
              content: '';
              border: 7px solid transparent;
              border-top: 0;
              border-bottom-color: rgba(163, 166, 168, 0.6);
              position: absolute;
              top: -7px;
              left: 50%;
              transform: translateX(-50%);
            }
          }
        }
      }

      .act {
        background-color: #2B81FF;
      }

      .a {
        background-image: url("../../assets/images/new/22.png");

        div {
          margin-left: 90px;

          &:before {
            margin-left: -90px;
          }
        }
      }

      .b {
        background-image: url("../../assets/images/new/23.png");
      }

      .c {
        background-image: url("../../assets/images/new/31.png");
      }

      .d {
        background-image: url("../../assets/images/new/30.png");
      }
    }

    .cash {
      display: flex;
      color: #333;
      justify-content: center;
      margin: 0 0 10px;
      align-items: center;

      span {
        color: #448af5;
        font-weight: bold;
      }
    }

    .h {
      position: relative;
      display: flex;
      height: 159px;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      & > div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }

      .img {
        width: 77px;
        height: 77px;
        margin: 23px 0 13px;
        background: center no-repeat;
        background-size: contain;
        cursor: pointer;
        border-radius: 50%;
      }
    }

    .us-name {
      font-size: 14px;
      line-height: 14px;
      text-align: left;
      color: #333;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;

      .v {
        width: 34px;
        height: 16px;
        border-radius: 7px;
        background: linear-gradient(-11deg, #036CEA, #84BEF8);
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        margin-right: 5px;
        &.u0{
          background: linear-gradient(-11deg, #ea8301, #fac729);
        }
        &.u2{
          background: linear-gradient(-11deg, #00802b, #1dd771);
        }
        &.u1{
          background: linear-gradient(-11deg, #7b13a7, #ba84f8);
        }
      }
    }

    .us-des {
      width: 77px;
      height: 16px;
      margin-top: 5px;
      background: pxUrl("us-des.png");
    }
  }

  .mus {
    position: relative;
    padding: 10px 0;
    border-radius: 10px;
    flex: 1;

    i {
      content: '';
      background: url("../../assets/images/new/m0.svg") no-repeat center;
      background-size: 61px 374px;
      display: inline-block;
      width: 28px;
      height: 28px;
      position: relative;
      margin: 0 10px 0 20px
    }

    .mu {
      align-items: center;
      cursor: pointer;
      color: #333;
      font-size: 13px;
      border-left: 4px solid transparent;

      span, a {
        display: flex;
        align-items: center;
      }

      &:hover .slide-mu-r {
        display: block;
      }

      &.act, &:hover {
        background: #f6fafe;
        border-left-color: #59a2fc;

        .i-fa {
          background-position: 0 -291px;
        }

        .i-g0 {
          background-position: 0 -149px;
        }

        .i-v {
          background-position: 0 5px;
        }

        .i-wa {
          background-position: 0 -210px;
        }

        .i-c {
          background-position: 0 -351px;
        }

        .i-h1 {
          background-position: 0 -83px;
        }

        .i-h2 {
          background-position: 0 -54px;
        }

        .i-h3 {
          background-position: 0 -179px;
        }

        .i-wi {
          background-position: 0 -319px;
        }

        .i-c1 {
          background-position: 0 -351px;
        }

        .i-h {
          background-position: 0 -114px;
        }

        .i-th {
          background-position: 0 -150px;
        }

        .i-pz {
          background-position: 0 -237px;
        }

        .i-m {
          background-position: 0 -264px;
        }

        .i-g1 {
          background-position: 0 -27px;
        }
      }
    }


    .i-g0 {
      background-position: -35px -149px;
    }

    .i-fa {
      background-position: -35px -291px;
    }

    .i-v {
      background-position: -35px 5px;
    }

    .i-wa {
      background-position: -35px -210px;
    }

    .i-c {
      background-position: -35px -351px;
    }

    .i-h1 {
      background-position: -35px -83px;
    }

    .i-h2 {
      background-position: -35px -54px;
    }

    .i-h3 {
      background-position: -35px -179px;
    }

    .i-wi {
      background-position: -35px -319px;
    }

    .i-c1 {
      background-position: -35px -351px;
    }

    .i-h {
      background-position: -35px -114px;
    }

    .i-th {
      background-position: -35px -150px;
    }

    .i-pz {
      background-position: -35px -237px;
    }

    .i-m {
      background-position: -35px -264px;
    }

    .i-g1 {
      background-position: -35px -27px;
    }
  }

  .dash-main {
    background: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 14px -8;
    flex: 1;
    position: relative;
    min-height: 670px;
    overflow: auto;
    font-size: 13px;
    padding: 30px;

    input, .x-select {
      border: 1px solid rgba(0, 0, 0, .05);
      border-radius: 3px;
      margin-right: 10px;
      height: 40px;
      display: flex;
      align-items: center;
    }

  }

  .xt {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0;
    border-radius: 0;
    background: none;
    box-shadow: none;
    overflow: visible;
  }

  .nbg{
    background: none;
    padding: 0;
    overflow: visible;
  }
}

.col-line {
  background: #f0f0f0;
  //background: url("./img/l.png") no-repeat;
  height: 1px;
  width: 100%;
  background-size: 100%;
}

.x-tab-a {
  display: flex;
  align-items: center;

  div {
    height: 43px;
    width: 117px;
    align-items: center;
    display: flex;
    justify-content: center;
    color: #fff;
    background: #e1e5ec;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    margin-right: 10px;

    &:after {
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 4px;
      background: #fff;
      position: absolute;
      right: 5px;
      top: 5px;
    }

    &.act {
      background: #2b81ff;
      pointer-events: none;
    }
  }
}
.dashboard .dash-main{
  .incPage,.divPage {
    .x-select{
      width: auto;
      min-width: 140px;
      padding: 0 20px 0 5px;
      .opts{
        left: auto;
      }
    }
  }
}
