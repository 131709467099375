@keyframes sss {
  50% {
    opacity: 1;
    transform: scale(1.6);
  }
  0%, 100% {
    opacity: .8;
    transform: scale(1);
  }
}

.live-page {
  flex-direction: column;

  &.c-page.bg0 {
    background-color: #fdfdfd;
  }

  .gb {
    position: relative;

    .nu {
      display: flex;
      height: 53px;
      position: absolute;
      width: 477px;
      top: 20px;
      right: 160px;
      align-items: center;

      b {
        background: url("./img/n.png");
        width: 34px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 4px;
        font-size: 28px;
        color: #4b5a68;
      }

      span {
        color: #fff;
        font-weight: 800;
        height: 30px;
        display: block;
        width: 16px;
        font-size: 30px;
        text-align: center;
        position: relative;
        top: 4px;
      }
    }
    .ept{
      pointer-events: none;
      opacity: .8;
      filter: grayscale(1);
    }
    .crd {
      position: relative;
      color: #fff;
      cursor: pointer;
      transition: .3s ease-in-out;

      &:hover {
        box-shadow: rgba(0, 0, 0, .4) 0 0 8px;
        transform: scale(1.01) perspective(1px);
      }

      .bns {
        transition: .5s ease-in-out;
        opacity: 0;
        position: absolute;
        bottom: 16px;
        right: 16px;
        width: 201px;

        .c0 {
          color: #ffbe3e;
        }

        .c1 {
          color: #ff803e;
        }

        & > div {
          font-weight: 600;

          i {
            margin: 0;
            padding: 0 8px 0 2px;
          }

          display: flex;
          align-items: center;
          justify-content: center;
          opacity: .95;

          &:hover {
            opacity: 1;
            transform: translate3d(0, -2px, 0);
            box-shadow: rgba(0, 0, 0, .4) 0 3px 8px;
          }

          box-shadow: rgba(0, 0, 0, .1) 0 1px 4px -2px;
          transition: .1s linear;
          cursor: pointer;
          height: 52px;
          background: #333 url("./img/e.jpg");
          margin-top: 8px;
        }
      }

      &:hover {
        .bns {
          opacity: 1;
        }
      }

      .rn {
        margin: 0 auto;
        position: relative;
        width: 80%;
        display: flex;
        align-items: center;
        font-size: 12px;

        i {
          width: 16px;
          height: 16px;
          display: block;
          background: #fff no-repeat center;
          background-size: 90% auto;
          border-radius: 50%;
          margin-right: 4px;
        }

        .p {
          i {
            background-image: url("./img/r.png");
            opacity: .5;
          }
        }

        .m {
          i {
            font-weight: 600;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            color: #333;
          }
        }

        & > div {
          display: flex;
        }

        & > div + div {
          margin-left: 5px;
        }
      }
    }

    .rr {
      .bns {
        right: 9px;
      }

      .rn {
        top: 264px;
      }
    }

    .r {
      .rn {
        margin: 0;
        left: 156px;
        width: auto;
        top: 100px;
      }
    }


    .ag {
      background-image: url("./img/ag.jpg");
      width: 219px;
      height: 412px;
    }

    .yb {
      background-image: url("./img/yb.jpg");
      width: 219px;
      height: 412px;
    }

    .ea {
      background-image: url("./img/ea.jpg");
    }

    .ab {
      background-image: url("./img/ab.jpg");
      width: 828px;
      height: 202px;
      .rn{
        position: absolute;
        left: 599px;
        top: 98px;
      }
      .bns{
        right: 30px;
      }
    }

    .wt{
      opacity: .5;
      font-weight: bold;
      font-size: 30px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 10;
    }

    .bb {
      background-image: url("./img/bb.jpg");
    }

    .eg {
      background-image: url("./img/eg.jpg");
    }

    .dg {
      background-image: url("./img/dg.png");
    }

    .se {
      background-image: url("./img/se.jpg");
    }

    .bg-a,
    .bg-b,
    .bg-c {
      left: 0;
      right: 0;
      position: absolute;
      pointer-events: none;
      background-size: 100% auto !important;
    }

    .bg-b {
      top: 104px;
      bottom: 94px;
      background: #3b3c4b;
    }

    .bg-c {
      z-index: 20;
      height: 124px;
      background: url("./img/box_.png") bottom center no-repeat;
      bottom: 0;
    }

    .bg-a {
      height: 104px;
      top: 0;
      background: url("./img/box.png") top center no-repeat;
    }

    .rv {
      padding-bottom: 10px;
      display: flex;
      overflow: hidden;
    }

    .cx {
      position: relative;
      width: 1240px;
      height: auto;
      margin: 40px auto 50px;
      padding: 92px 18px 100px;

      & > i {
        position: absolute;
        background: url("./img/c.png");
        width: 90px;
        height: 74px;
        left: -30px;
        top: -20px;
        z-index: 10;
      }

      .rr, .r {
        width: 450px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 12px;
      }

      .rx {
        width: 828px;
      }

      .r {
        width: auto;
        margin: 0;
        &.nf{
          flex: none;
        }
        & > div {
          width: 368px;
          height: 198px;
          background-size: 100% 100%;
        }
        flex: 1;
        flex-wrap: wrap;
      }
    }
  }

  .fkb {
    overflow: hidden;
    height: 62px;
    background: linear-gradient(#fefefe, #e3e2e8);
    border-bottom: 1px solid #cfcecf;

    .cx {
      display: flex;
      position: relative;

      .fk-l {
        height: 61px;

        & + .fk-l {
          padding-left: 20px;

          &:before {
            top: 50%;
            transform: translateY(-50%);
            content: '';
            z-index: 5;
            left: 0;
            display: block;
            position: absolute;
            width: 1px;
            border-right: 1px solid #fff;
            background: #aaa;
            height: 40px;
            opacity: .4;
          }
        }
      }

      .jb {
        background: url("./img/b.png");
        width: 47px;
        height: 53px;
      }

      span {
        flex: 1;
      }

      .f-mk {
        background: linear-gradient(#fefefe, transparent, #e3e2e8);;
      }

      .fg {
        width: 90%;
        height: 61px;
        justify-content: space-between;
        font-size: 14px;
      }

      .pz {
        background: url("./img/b1.png") no-repeat;
        background-size: 100% 100%;
        height: 36px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        justify-content: center;
        min-width: 124px;

        span {
          font-size: 20px;
          font-weight: 600;
          color: #3b84f3;
        }
      }

      & > div {
        flex: 1;
      }
    }
  }

  .rd {
    background: url("./img/h.png") top center no-repeat;
    width: 1237px;
    margin: 70px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding-top: 60px;
    font-size: 50px;
    letter-spacing: 2px;

    b {
      position: relative;
      left: 5px;
      top: 10px;
      font-size: 18px;
      width: 22px;
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #fff;
      color: #4b54a7;
    }
  }

  .hv {
    height: 577px;
    background: #3e499e;
    position: relative;
    overflow: hidden;

    .ls {
      width: 1240px;
      margin: 50px auto 0;
      display: flex;
      justify-content: space-between;

      a {
        transition: .2s ease-in-out;

        &:hover {
          transform: translate3d(0, -2px, 0) scale(1.01);
          box-shadow: rgba(0, 0, 0, .3) 0 15px 20px -10px;
        }

        height: 275px;
        width: 32%;
        background: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        & > div:not(.f) {
          flex: 1;
          background-color: #eee;
          background-size: 100% 100%;
        }

        .f {
          height: 83px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: linear-gradient(#fefefe, #e4e4e7);
          font-size: 13px;
          color: #333;

          i {
            display: block;
            width: 20px;
            height: 20px;
            background: #d1b07a center no-repeat;
            border-radius: 50%;
            margin-right: 8px;
            background-size: 80% auto !important;
          }

          .m {
            background-image: url("./img/a.svg");
          }

          .p {
            background-image: url("./img/b.svg");
          }

          .f0 {
            font-size: 13px;
          }

          .f1 {
            color: #003960;
            font-size: 24px;
            padding-top: 5px;
          }

          & > div {
            div {
              display: flex;
              align-items: center;
            }

            flex: 1;
            align-items: center;
            justify-content: center;
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    .c0 {
      background-image: url("./img/a0.jpg");
    }

    .c1 {
      background-image: url("./img/a1.jpg");
    }

    .c2 {
      background-image: url("./img/a2.jpg");
    }

    & > div {
      position: relative;
      z-index: 2;
    }

    &:before {
      animation: sss 20s infinite linear;
      position: absolute;
      content: '';
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(74, 74, 159, .6) url("./img/bg.png") center no-repeat;
      background-size: 100% 100%;
      z-index: 2;
    }

    canvas {
      z-index: 0;
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .cx {
    width: 1240px;
    margin: 0 auto;
  }
}
