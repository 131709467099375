.confirm-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  visibility: hidden;
  z-index: -1;
  pointer-events: none;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  transition: opacity .3s ease-in-out;

  &.act {
    visibility: visible;
    z-index: 960;
    pointer-events: all;
  }

  .l {
    position: fixed;
    left: 150px;
    right: 150px;
    bottom: 150px;
    top: 150px;
  }

  .c {
    position: relative;
    z-index: 10;
    width: 400px;
     margin: 0 auto;
    .c-logo {
      background: url("./a.png") no-repeat;
      width: 77px;
      height: 77px;
      display: block;
      border-radius: 50%;
      margin: 0 auto -40px;
      box-shadow: rgba(0, 0, 0, 0.2) 0 4px 10px;
      position: relative;
      z-index: 3;
    }

    .t {
      user-select: none;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      font-size: 14px;
      color: #666;
      text-align: center;

      i {
        position: absolute;
        right: 10px;
        top: 60px;
        font-style: normal;
        font-size: 12px;
        color: #999;
        cursor: pointer;

        &:hover {
          color: #333;
        }
      }

      & + .ctx {
        padding: 20px 10px 6px;
      }
    }

    .chk {
      label {
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-top: 20px;
        flex: 1;
        font-size: 13px;
        min-width: 90px;
        color: #424242;

        input {
          margin-right: 4px;
        }
      }
    }

    .ctx {
      padding: 60px 0 0;
      border-radius: 4px;
      box-shadow: rgba(0, 0, 0, .4) 0 16px 50px -16px, inset rgba(0, 0, 0, 0.05) 0 2px 10px;
      font-size: 14px;
      text-align: center;
      color: #333;
      white-space: pre-wrap;
      width: 100%;
      word-break: break-all;
      display: flex;
      flex-direction: column;
      background: rgba(240, 241, 241, 0.95);
    }
  }
 .c-ctx{
   padding: 5px 20px 25px;
 }
  .mask {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
  }

  .btn {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    div {
      flex: 1;
      height: 60px;
      &:hover {
        background: linear-gradient(90deg, #50b3fc, #1271fa);
      }
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      cursor: pointer;
      background: linear-gradient(90deg, #56b7ff, #2b81ff);
      border-radius: 2px;
      color: #fff;
    }

    .n {
      background: linear-gradient(90deg, #d6d6d6, #bcc2c9);
      &:hover{
        background: linear-gradient(90deg, #d0d0d0, #b4b4b4);
      }
    }
  }
}
