.x-pg {
  height: 40px;
  align-items: center;
  display: flex;

  i {
    flex: 1;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .pg-p {
    cursor: pointer;
    overflow: hidden;
    transition: .2s ease-in-out;
    width: 28px;
    margin: 0 3px;
    height: 28px;
    border-radius: 4px;
    position: relative;
    background: linear-gradient(#fff, #e2e7f3);
    font-size: 12px;
    color: #999;
    border: 1px solid rgba(0, 0, 0, 0.05);
    &.pg-st,&.pg-en{
      width: 14px;
    }
   &:hover, &.act {
     color: #fff;
     background: linear-gradient(#4d95ff, #2981fd);
     box-shadow: #2783ff 0 4px 14px -6px;
   }
    &.dis {
    pointer-events: none;
    opacity: .5;
  }
  }
  .pg-m{
     color: #eee;
    font-weight: 900;
    font-size: 20px;
    line-height: 1;
    padding-bottom: 5px;
  }
}
