.vip-page {
  .sec{
    h2{
      height: auto;
      line-height: 1;
    }
  }
  .vip-e{
    width: 242px;
    height: 104px;
    border-radius: 6px;
    background: #ecebf4;
    border: 1px solid #ecebf3;
    display: flex;
    align-items: center;
    margin: 6px;
    &.lok{
      position: relative;
      .k{
        background: #323235 url("./img/l.png") no-repeat center;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
    div{
      display: flex;
      flex-direction: column;
      b{
        font-weight: 400;
        color: #59a7e2;
      }
      s{
        display: block;
        height: 1px;
        width: 110px;
        background: linear-gradient(90deg,#bfd1f2,transparent);
        margin: 10px 0;
      }
      span{
        display: block;
      }
    }
    .vip-btn{
      padding: 0;
      margin: 0 20px;
      &:after{
        background-position: center;
        background-size: 60%
      }
      width: 55px;
      height: 80px;
      border-radius: 30px;
      box-shadow: none;
      &:before{
        content: none;
      }
    }
    &.act{
      background: #fff;
    }

  }
  .ls{
    width: 1280px;
    margin-left: -14px;
    display: flex;
    flex-wrap: wrap;;
    margin-bottom: 20px;
  }
  .a {
    background: #fff url(../../assets/images/new/v.jpg) center no-repeat;
    background-size: cover;
    padding-top: 45.31%;
  }

  .b {
    margin: -20% 0 30px;
    display: flex;
    flex-wrap: wrap;
  }


  .c {
    margin: -240px 0 30px;
    position: relative;
    min-height: 382px;
    display: flex;
    justify-content: space-between;

    & > i {
      position: relative;
      top: 220px;
      cursor: pointer;
      border-radius: 50%;
      background: rgb(236, 235, 244) url("./img/v.png") center no-repeat;
      width: 59px;
      height: 59px;
      transition: .3s ease-in-out;

      &:hover {
        background-color: #0d9fff;
      }
    }
    .c1{
      transform: rotateY(180deg);
    }
  }

  .d {
    background: url("./img/vb.png");
    width: 1022px;
    height: 469px;
    margin: -100px auto 0;
    padding-top: 290px;
    position: relative;
    color: #fff;

    .vip-btn {
      left: 10px;
      top: 244px;
      position: absolute;
      box-shadow: none;
      background: none;
      height: 100px;

      &:before {
        content: none;
      }

      &:after {
        background-size: auto 100%;
      }
    }

    span {
      position: absolute;
      right: 10px;
      top: 260px;
      width: 120px;
    }

    h1, h3 {
      color: #fff;
      margin: 0;
      line-height: 2;
      text-align: center;
    }

    .i {
      position: absolute;
      bottom: 0;
      left: 20px;
      right: 20px;
      height: 65px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      border-top: 1px solid rgba(255, 255, 255, .3);
    }
  }

  .e {
    background: #fff url("../../assets/images/new/d.jpg") bottom;
    width: 650px;
    min-height: 344px;
    border-radius: 8px;
    margin-left: 20px;
    border-collapse: collapse;

    .s {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .btn {
      color: #fff;
      text-align: center;
      line-height: 40px;
      width: 182px;
      border-radius: 30px;
      background: linear-gradient(90deg, #61a7fa, #3079f5);
      box-shadow: #61a7fa 2px 8px 24px -10px;
      cursor: pointer;
      opacity: .85;
      transition: .2s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }

    td {
      padding: 0 30px;
      width: 50%;
      border: 4px solid #eee;
    }

    tr:first-child td {
      border-top: 0;
    }

    tr:last-child td {
      border-bottom: 0;
    }

    td:first-child {
      border-left: 0;
    }

    td:last-child {
      border-right: 0;
    }
  }

  .f {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 30px;

    table {
      width: 100%;
      border-collapse: collapse;
    }

    h1 {
      margin: 0;
      color: #fff;
      font-weight: 600;
      height: 70px;
      font-size: 23px;
      text-align: center;
      line-height: 70px;
      background: #4080fe;
    }

    p {
      line-height: 4;
      color: #72b2f9;
    }

    th, td {
      height: 70px;
      border: 1px solid #cbd2de;
      text-align: center;

      &.act {
        background: #4080fe;
        color: #fff;
      }
    }
  }

  .g {
    margin-bottom: 30px;

    h2 {
      line-height: 3;
      text-align: left;
    }

    p {
      position: relative;
      padding-left: 30px;
      line-height: 2;
    }

    b {
      display: block;
      position: absolute;
      left: 0;
      top: 6px;
      width: 20px;
      height: 20px;
      color: #fff;
      border-radius: 50%;
      background: #4080fe;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      font-size: 13px;
    }
  }
}
