.bg-a {
  position: relative;
  background: url("../../assets/images/new/8.png") no-repeat 50% -30px;
  background-size: auto 100%;
  min-height: 800px;
}
.sec {
  h1, h2 {
    margin: 0;
    font-weight: 400;
  }
  h2 {
    height: 32px;
    color: #9c9c9c;
    font-size: 24px;
    font-weight: 200;
    line-height: 32px;
    letter-spacing: 2px;
    margin-top: 26px;
  }

  h1 {
    height: 48px;
    font-size: 36px;
    font-weight: 200;
    line-height: 48px;
    letter-spacing: 3px;
    color: #468bfb;
  }

  @keyframes round {
    0% {
      -webkit-transform: translate3d(0, 0, 0) scale(1);
      transform: translate3d(0, 0, 0) scale(1)
    }
    10% {
      -webkit-transform: translate3d(3px, 6px, 0) scale(.95);
      transform: translate3d(3px, 6px, 0) scale(.95)
    }
    20% {
      -webkit-transform: translate3d(8px, 10px, 0) scale(.9);
      transform: translate3d(8px, 10px, 0) scale(.9)
    }
    40% {
      -webkit-transform: translate3d(6px, 14px, 0) scale(.8);
      transform: translate3d(6px, 14px, 0) scale(.8)
    }
    50% {
      -webkit-transform: translate3d(3px, 12px, 0) scale(.9);
      transform: translate3d(3px, 12px, 0) scale(.9)
    }
    70% {
      -webkit-transform: translate3d(0, 4px, 0) scale(.92);
      transform: translate3d(0, 4px, 0) scale(.92)
    }
    80% {
      -webkit-transform: translate3d(-4px, 0, 0) scale(.96);
      transform: translate3d(-4px, 0, 0) scale(.96)
    }
    90% {
      -webkit-transform: translate3d(-4px, -4px, 0) scale(.98);
      transform: translate3d(-4px, -4px, 0) scale(.98)
    }
    100% {
      -webkit-transform: translate3d(0, 0, 0) scale(1);
      transform: translate3d(0, 0, 0) scale(1)
    }
  }
  &.c1{
     display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
   a{
     border-radius: 50px 10px ;
     width: 400px;
     height: 280px;
     margin-bottom: 35px;
     transition: .3s ease-in-out;
     background-size: cover;
     &:hover{
       transform: translate3d(2px,-2px,2px);
       box-shadow: rgba(0, 78, 184, 0.5) 2px 5px 14px -5px;
     }
   }
    
    .li{
      background-image: url("./img/li.png");
    }
    .sp{
      background-image: url("./img/sp.png");
    }
    .lo{
      background-image: url("./img/lo.png");
    }
    .el{
      background-image: url("./img/el.png");
    }
    .fi{
      background-image: url("./img/fi.png");
    }
    .sl{
      background-image: url("./img/sl.png");
    }
 }
  &.c2 {
    display: flex;
    margin: 86px auto;
    i {
      background: url("../../assets/images/new/13.png") no-repeat center;
      width: 664px;
      height: 601px;
      display: block;
      position: relative;
      left: -60px;
      background-size: contain;
      b{
        display: block;
        position: absolute;
        animation: round 15s both infinite linear;
      }
      .t0{
        background-image: url(../../assets/images/new/12.png);
        width: 68px;
        height: 68px;
        left: 580px;
        top: 260px;
        animation-delay: 2s;
        animation-direction: reverse;
      }
      .t1{
        background-image: url(../../assets/images/new/20.png);
        width: 101px;
        height: 100px;
        left: 30px;
        top: 260px;
        animation-delay: 2s;
        animation-duration: 8s;
      }
      .t2{
        background-image: url(../../assets/images/new/10.png);
        width: 110px;
        height: 112px;
        left: 500px;
        top: 160px;
        animation-direction: reverse;
        animation-duration: 8s;
      }
      .t3{
        background-image: url(../../assets/images/new/11.png);
        width: 84px;
        height: 84px;
        left: 590px;
        top: 100px;
        animation-delay: 1s;
        animation-duration: 7s;
      }
      .t4{
        background-image: url(../../assets/images/new/21.png);
        width: 132px;
        height: 126px;
        left: 470px;
        top: 320px;
        animation-delay: 2s;
        animation-duration: 16s;
      }
    }

    .dow {
      display: flex;
      justify-content: space-between;
      line-height: 2;
      text-align: center;
      padding:  0 10px;
      margin-top: 76px;
     s{
       display: block;
       background: center no-repeat;
       background-size: 100% 100%;
       width: 28px;
       height: 28px;
     }
      .ios{
        background-image: url("./img/ios.svg");
        background-size: 85% 85%;
      }
      .ard{
        background-image: url("./img/android.svg");
      }
      .c {
        span{
          display: block;
          height: 33px;
          line-height: 33px;
          width: 195px;
          margin-bottom: 5px;
          border-radius: 4px;
          background: #e0faff;
          color: #00bfed;
          font-size: 12px;
          font-weight: 200;
          letter-spacing: 1px;
          text-align: center;
        }
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
          font-size: 13px;
          color: #999;
        }

        div {
          margin: 10px auto;
          width: 155px;
          height: 155px;
        }
      }

      .we {
        background: url(./img/p.svg) no-repeat center;
        background-size: 100% 100%;
      }

      .qr {
        padding: 10px;
        border: 1px solid #f2f2f2;
        background: #fff url(../../assets/images/new/q.png);
        border-radius: 4px;
        background-size: 100% 100%;
      }

      h3 {
        margin: 0;
        font-weight: 400;
        font-size: 13px;
        color: #0d9fff;
      }

      span {
        font-size: 14px;
        color: #036dfc;
      }
    }

    .r {
      flex: 1;
      padding: 20px;
      h1, h2 {
        text-align: left;
        white-space: nowrap;
      }

      p {
        line-height: 1.8;
        color: #999;
        font-size: 14px;
        margin-top: 16px;
      }
    }
  }
}

.ndm {
  position: absolute;
  left: 30px;
  right: 10px;
  bottom: 15px;
  display: flex;
  align-items: center;
  height: 100px;
  color: #000;
  font-size: 22px;

  & > span {
    display: flex;
    align-items: center;
  }

  b {
    padding: 0 19px;
    font-size: 34px;
    font-weight: 600;
    color: #4b89f4;
    text-shadow: #002a9d 0 0 2px 1px;
  }

  & > i {
    display: block;
    background: url("../../assets/images/new/9.png");
    width: 136px;
    height: 129px;
    margin-right: 10px;
  }

  & > .r {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 20px;
    height: 32px;
    border-left: 1px solid #ddd;
    .slideToWraper {
      font-size: 15px;
      flex: 1;
      height: 32px;

      .slideToChild:before {
        display: none;
      }

      .slide_prize {
        color: #3880fb;
      }
    }

    & > i {
      background: url("../../assets/images/new/a.png");
      width: 154px;
      height: 40px;
      margin: 0 20px;
    }
  }
}

.cpr{
  font-size: 12px;
  line-height: 60px;
  color: #a6a6a9;
  a{
    color: #a6a6a9;
    padding: 0 8px;
  }
}
.hm-f{
  margin-top: 80px;
  margin-bottom: 0;
  height: 646px;
  position: relative;
  overflow: hidden;
  .sec{
    padding: 3% 20px 0;
    height: 646px;
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .a{
       background-image: url("./img/v0.png");
    }
    .b{
       background-image: url("./img/v1.png");
    }
  }
  h1,h2{
    text-align: center;
  }
  h1{
    height: 48px;
    color: #1186ff;
    font-size: 36px;
    font-weight: 200;
    line-height: 48px;
    letter-spacing: 2.7px;
  }
  h2{
    height: 32px;
    color: #9c9c9c;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 1.8px;
  }
  .bg,.bg1{
    position: absolute;
    top: 8%;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .bg{
    left: 0;
    background: #ebf2fe;
    transform:skewY(5deg);
    transform-origin: top left;
  }
  .bg1{
    right: 0;
    transform-origin: top right;
    transform:skewY(-5deg);
    &:before,&:after{
      content: '';
      position: absolute;
      top: 1px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      transform-origin: top right;
      transform:skewY(5deg);
    }
    &:after{
      background: #2351bd;
      opacity: 0.82477677;
    }
    &:before{
      background: url("./img/b.png") top center no-repeat;
      background-size: cover;
    }
  }
}
