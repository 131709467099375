@import "../../styles/anim";
.fish-card {
  position: relative;
  background: url(./img/fish/more.png);
  width: 396px;
  height: 298px;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, .05) 0 2px 5px;
  border-radius: 90px;
  transition: .3s;
  .launchGame {
    position: absolute;
    background: url(./img/fish/st.png);
    width: 242px;
    height: 84px;
    transition: .1s;
    cursor: pointer;
    left: 18px;
    bottom: 57px;
    opacity: .9;
    &:hover{
      opacity: 1;
    }
  }
  &.d3 {
    background-image: url(./img/fish/ag.png);
  }
  &.d4 {
    background-image: url(./img/fish/jdb.png);
  }
  &.d0 {
    background-image: url(./img/fish/gg.png);
  }
  .load-money {
    position: absolute;
    height: 42px;
    width: 250px;
    top: 93px;
    left: 29px;
    padding: 0 20px;

    span {
      line-height: 42px;
      font-size: 24px;
      color: #fff;
    }
    .yellow {
      padding: 0 10px;
    }
  }
  i {
    background: url(./img/fish/ld.png);
    width: 34px;
    height: 34px;
    position: absolute;
    right: -44px;
    top: 3px;
    cursor: pointer;
    &.ld{
      animation: 5s rotateZ360 both linear infinite;
    }
  }

  .open-wallet {
    cursor: pointer;
  }
}

.fish-page {
  background: #2668d6;
  flex-direction: column;
  canvas {
    z-index: 1;
    pointer-events: none;
    position: absolute;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .bg {
    background: #2668d6 url(./img/fish/bg.jpg);
    background-size: cover;
    height: 0;
    box-sizing: content-box;
    padding-top: 33.958%;
    width: 100%;
    position: relative;
    box-shadow: inset #2668d6 0 -10px 50px;
  }

  .ctx {
    margin-bottom: -2px;
    position: relative;
    padding: 10px;
    z-index: 2;
    .lmt{
      width: 1280px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
    }
  }

  .ft {
    background: #2668d6 url(./img/fish/ft.jpg);
    background-size: cover;
    height: 0;
    box-sizing: content-box;
    padding-top: 12.45%;
    box-shadow: inset #2668d6 0 20px 50px;
  }
}
