$sd: rgba(0, 0, 0, .15) 0 4px 14px -8px;
.crd.x-date-out .x-dd{
  transform: translateX(-45%);
}
.anPage {
  .x-st-ti {
    box-shadow: $sd;
    margin-bottom: 0;
  }

  .ac {
    position: relative;
    display: flex;
    margin-top: 10px;
    background: #fff;
    box-shadow: $sd;
    padding: 20px;
  }

  .lb {
    background: currentColor;
    box-shadow: currentColor 0 4px 20px -10px;
    display: flex;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 50px 20px;
    height: 100px;
    width: 100px;
    span{
      color: #fff;
    }
  }
  .x-dates{
    font-size: 12px;
    border-radius: 4px;
    height: 30px !important;
    margin-right: 5px !important;
    display: flex;
    align-items: center;
    border: 1px solid #dedede !important;
    padding: 0 5px;
    min-width: 90px;
    position: absolute;
    right: 137px;
    top: 13px;
    z-index: 3;
  }
  .x-dp-q,.tt{
    display: flex;
    position: absolute;
    top: 15px;
    right: 20px;
  }
  .ct {
    flex: 1;
  }

  .cs {
    margin: 10px 0;
  }

  .ro {
    padding-top: 10px;
    display: flex;
    justify-content: space-evenly;
  }

  .ce {
    padding: 10px 0;
    width: 180px;
    text-align: right;
    position: relative;
    h5 {
      font-weight: 200;
      line-height: 3;
      font-size: 15px;
    }

    p {
      font-size: 18px;
    }
    &+.ce:after{
      left: 40px;
      top: 25%;
      background: #e8e8e8;
      width: 1px;
      content: '';
      display: block;
      height: 50%;
      position: absolute;
    }
  }
  .no-data i{
    display: none;
  }
  .a0 .lb{
    color: #a6d596;
  }

  .a1 .lb{
    color: #75c3e5;
  }

  .a2 .lb{
    color: #b9b19a;
  }

  .tt {
    border-radius: 100px;
    background: #f3f4fc;
    div{
      font-size: 12px;
      height: 30px;
      line-height: 30px;
      width: 90px;
      text-align: center;
      position: relative;
      z-index: 2;
      cursor: pointer;
    }
    i {
      border-radius: inherit;
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 90px;
      height: 30px;
      transform: translate3d(0,0,0);
      transition: .2s;
      background: linear-gradient(90deg, #60a7fa, #65b9e7);
      box-shadow: #0a94e3 0 2px 6px -4px;
    }

    .act {
      transition: .2s;
      color: #fff;
      & +div+ i {
        transform: translate3d(100%,0,0);
      }
      &+ i {
        transform: translate3d(200%,0,0);
      }
    }
  }
  .a2{
    .x-dates{
      display: none;
    }
  }
  .a1,.a2{
    .ct{
      margin-top:40px;
      margin-left: 100px;
    }
  }
  .t{
    flex: 1;
    &+.t{
      margin-left: 10px;
    }
  }
  .ts{
    display: flex;
  }
  .x-table thead th{
    background: #f3f7fc;
    color: #666;
    height: 30px;
  }
}