.sport-page{
  flex-direction: column;
  background: #181b20;
  .si{
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    padding-top: 10px;
    width: 80px;
    .sp-btn{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: 12px;
      margin-bottom: 20px;
      transition: .2s ease-in-out;
      opacity: .7;
      position: relative;
      background: transparent;
      border-radius: 50%;
      width: 60px;
      height: 90px;
      span{
        width: 100%;
        display: block;
        height: 14px;
        line-height: 14px;
        margin-top: 10px;
      }
      &.act{
        opacity: 1;
      }
      &:hover{
        background: none;
        i{
          transform: scale(1.1);
        }
      }
      i{
        height: 58px;
        width: 58px;
        display: block;
      }
    }
  }
  .ff{
    position: relative;
    overflow: hidden;
  }
   canvas{
     opacity: .5;
     position: absolute;
     top: 0;
     left: 0;
     display: block;
     width: 100%;
     height: 100%;
   }
  .bg0 {
    position: absolute;
    top: 0;
    bottom: 0;
    background: #22272d url(./img/bg_h.jpg) no-repeat center;
    width: 100%;
    height: 0;
    box-sizing: content-box;
    padding-top: 56.823%;
    box-shadow: inset #181b20 0 -50px 50px;
    background-size: 100% 100%;
  }
  .bg1 {
    position: absolute;
    box-shadow: inset #000 0 50px 1000px 10px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .sn {
    width: 1240px;
    margin: 14% auto 0;
    position: relative;
  }
  .sh {
    background: url(./img/ti.png);
    width: 935px;
    height: 274px;
    margin: 0 auto;
    padding: 1px 0;
  }
  .sm {
    margin-top: -10px;
    position: relative;
    transition: .5s ease-in-out;
    padding: 20px 0 1px;
    margin-bottom: 60px;
    box-shadow: rgba(0,0,0,.2) 0 2px 3px;
    &.gm{
      height: 1200px;
      height: 100vh;
    }
    .gg{
      overflow: hidden;
      top: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      opacity: 0;
      transition: .5s ease-in-out;
      background: #0f1319;
      &.act{
        top: 0;
        opacity: 1;
      }
      iframe{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        bottom: 0;
        top: 0;
        border: 0;
      }
    }
    s {
      display: inline-block;
      opacity: .3;
      margin-left: 3px;
      &:hover{
        opacity: 1;
      }
    }
  }
  .c-bg {
    width: 888px;
    background: center no-repeat;
    background-size: cover;
    float: left;
  }
  .lc {
    float: left;
    background: #444;
    width: 216px;
    height: 215px;
    position: relative;
  }
  .l-bg {
    position: absolute;
    top: 0;
    left: 0;
    background: url(./img/bg_0.jpg);
    width: 216px;
    height: 161px;
    i {
      margin: 10px auto;
      display: block;
      background: #eee center no-repeat;
      border-radius: 50%;
      height: 58px;
      width: 58px;
    }
    h3 {
      text-align: center;
      color: #fff;
      font-size: 16px;
      font-weight: 400;
    }
    div {
      cursor: pointer;
      text-align: center;
      color: #fff;
      margin: 14px 0;
      font-size: 18px;
      span {
        color: #f86022;
        font-size: 20px;
      }
    }
  }
  .alb{
    .c-bg{
      background-image: url(./img/188_bg.png);
      height: 237px;
      margin-top: -20px;
    }
     i {
      background-image: url(./img/188_ico.png);
    }
  }
  .bti{
    .c-bg{
      background-image: url(./img/bti_bg.png);
      height: 229px;
      margin-top: -14px;
    }
    i {
      background-image: url(./img/bti_ico.png);
    }
  }
  .bt{
    .c-bg{
      background-image: url(./img/bt_bg.png);
      height: 253px;
      margin-top: -38px;
    }
    i {
      background-image: url(./img/bt_ico.png);
    }
  }
  .im{
    transform: translateY(5px);
    .c-bg{
      background-image: url(./img/im_bg.png);
      height: 240px;
      margin-top: -24px;
    }
    i {
      background-image: url(./img/im_ico.png);
    }
  }
  .bs{
    .c-bg{
      background-image: url(./img/bs_bg.png);
      height: 226px;
      margin-top: -11px;
    }
    i {
      background-image: url(./img/bs_ico.png);
    }
  }
  .ob{
    .c-bg{
      background-image: url(./img/bt_bg.png);
      height: 253px;
      margin-top: -38px;
    }
    i {
      background-image: url(./img/bt_ico.png);
    }
  }
  .sp-card {
    margin: 0 auto 25px;
    width: 1104px;
    height: 215px;
  }
  .launchGame {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background: #363636;
    height: 54px;
    line-height: 54px;
    text-align: center;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    transition: .2s ease-in-out;
    &:hover{
      background: #535353;
    }
  }
}
