.vip-c-ls {
  &:after{
    position: absolute;
    content: '';
    bottom: -30px;
    left: -120px;
    right: -120px;
    height: 30px;
    display: block;
    background: linear-gradient(#f1f1f1,transparent);
    border-radius: 30px 30px 0 0;
  }
  position: relative;
  width: 610px;
  margin:  40px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .bg{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top:0;
    z-index: 0;
    background: rgba(255, 255, 255, 0.7) url("./img/1.png") no-repeat 230px -10px;
  }
  label{
    background: #84c0ff;
    font-size: 12px;
    letter-spacing: 1px;
    color: #fff;
    width: 90px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 15px 0 15px;
  }
  .ct{
    position: relative;
    display: flex;
    font-size: 12px;
    b{
      padding:  0 2px;
      font-weight: 200;
      font-size: 15px;
    }
    h1{
      font-size: 16px;
      color: #22b3ed;
      font-weight: 800;
      letter-spacing: 1px;
    }
    s{
     display: block;
      background: url("./img/0.png");
      width: 44px;
      height: 38px;
      margin-right: 10px;
    }
  }
  &>i{
    position: relative;
    z-index: 5;
    width: 33px;
    height: 33px;
    background: #ffffff url("./img/r.png") no-repeat center;
    box-shadow: 0 4px 5px rgba(223,223,223,0.5);
    border-radius: 50%;
    opacity: .8;
    cursor: pointer;
    &:hover{
      opacity: 1;
    }
  }
  .p{
    transform: rotateY(180deg);
  }
  .d{
    pointer-events: none;
    opacity: .5;
  }
  .sc {
    overflow: hidden;
    width: 436px;
    height: 127px;
    border-radius: 15px 15px 0 0;
  }
  .ro{
    position: relative;
    height: 34px;
    width: 340px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div{
      width: 248px;
      height: 7px;
      border-radius: 3.5px;
      background: rgba(119,119,119,0.26);
    }
    span{
      color: #fff;
      font-size: 12px;
      text-align: center;
      font-weight: 600;
      width: 34px;
      height: 16px;
      border-radius: 7px;
      background: linear-gradient(-11deg, #036CEA, #84BEF8);
    }
  }
  .s{
    transition: .5s ease-in-out;
    display: flex;
    width: 9 * 436px;
    height: 127px;
  }
  .vip-card {
    p{
      position: relative;
      color: #aaa;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 436px;
    height: 127px;
  }
  .v-0{
    background: radial-gradient(circle at 50% 0, #B9DEFB, #83BAF5);
  }
  .v-1{
    background: radial-gradient(circle at 50% 0, #B9DEFB, #1C6BBF);
  }
  .v-2{
    background: radial-gradient(circle at 50% 0, #B9DEFB, #14319D);
  }
  .v-3{
    background: radial-gradient(circle at 50% 0, #B9DEFB, #0C0189);
  }
  .v-4{
    background: radial-gradient(circle at 50% 0, #B9DEFB, #F5E683);
  }
  .v-5{
    background: radial-gradient(circle at 50% 0, #83BAF5, #FFA836);
  }
  .v-6{
    background: radial-gradient(circle at 50% 0, #83BAF5, #F58393);
  }
  .v-7{
    background: radial-gradient(circle at 50% 0, #83BAF5, #76787A);
  }
  .v-8{
    background: radial-gradient(circle at 50% 0, #83BAF5, #000000);
  }
}
.vip-c{
  transition: .2s ease-in-out;
  &:hover{
    transform: translate3d(0,-1px, -2px);
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 7px -3px;
  }
  i{
    display: block;
    width: 64px;
    height: 100%;
    transition: .3s ease-in-out;
    background: url("./img/b.png") no-repeat center;
  }
  .i-1{
    background-image: url("./img/3.png");
  }
  .i-2{
    background-image: url("./img/4.png");
  }
  .i-3{
    background-image: url("./img/5.png");
  }
  .i-4{
    background-image: url("./img/6.png");
  }
  .i-5{
    background-image: url("./img/7.png");
  }
  .i-6{
    background-image: url("./img/8.png");
  }
  .i-7{
    background-image: url("./img/9.png");
  }
  .i-8{
    background-image: url("./img/a.png");
  }
  width: 186px;
  height: 70px;
  border-radius: 3px;
  border: solid 1px #dbdbdb;
  background: #f9f9f9;
  margin-bottom: 27px;
  padding: 15px 0 15px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h1{
    font-weight: 400;
    font-size: 14px;
  }
  h1+div{
    height: 1px;
    background: #000;
    width: 30px;
    margin:  4px 0;
  }
  span{
    font-size: 12px;
    color: #999;
  }
}
.vipPage{
  .u{
    padding:  0 20px;
    display: flex;
    justify-content: flex-end;
  }
  a{
    &:hover{
      opacity: 1;
    }
    opacity: .8;
    color: #377ef6;
    width: 116px;
    height: 31px;
    border-radius: 8px;
    border: solid 1px #dae4f1;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .ls{
    margin: 50px auto;
    display: flex;
    flex-wrap: wrap;
    width: 821px;
    align-items: center;
    justify-content: space-between;
  }
}
