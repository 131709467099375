@import "~react-calendar/dist/Calendar.css";

.x-dp-q {
  display: flex;

  .bn {
    width: auto !important;
    padding: 0 5px;

    &.act {
      color: #fff;
      background: #87c1fa;
      border: none;
    }
  }
}

.x-dates {
  & > i {
    width: 20px;
    display: block;
    background: url("./b.svg") no-repeat center right;
    height: 28px;
    background-size: auto 60%;
  }

  cursor: pointer;

  span {
    padding: 0 3px;
  }
}

.x-date-out {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 100;
  transition: opacity .2s ease-in-out;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;

  &.a, &.b {
    visibility: visible;
  }

  &.a.b {
    pointer-events: all;
    opacity: 1;
  }

  .react-calendar__month-view__days__day--weekend {
    color: #555;
  }

  .react-calendar__tile {
    padding: 4px;
    color: #333;
  }

  .react-calendar__navigation__label__labelText {
    color: #000;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus,
  .react-calendar__tile--hasActive {
    color: #fff;
    background: #70bff5;
    border-radius: 6px;
  }

  .react-calendar__tile--hover,
  .react-calendar__tile--range {
    border-radius: 0;
    background: #eff8fe;
    color: #000;
  }

  .react-calendar__tile--rangeStart,
  .react-calendar__tile--rangeEnd {
    background: #70bff5;
    color: #fff;
    border-radius: 6px;
  }

  .react-calendar__navigation__arrow {
    min-width: 20px;
    color: #70bff5;
    width: 28px;
    height: 28px;
    border: 1px solid #d2d8fa;
    border-radius: 5px;

    &:hover {
      background: #f4f8ff !important;
    }
  }

  .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {

  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: #ccc;
  }

  .react-calendar__month-view__weekdays {
    abbr {
      text-decoration: none;
      color: #70c0f6;
      font-weight: 400;
      font-size: 13px;
    }
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button,
  .react-calendar__navigation__label__divider {
    display: none;
  }

  .react-calendar__navigation__label {
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .react-calendar__navigation {
    border-bottom: 1px solid #f2f2f2;
    align-items: center;
  }

  .react-calendar {
    width: 460px;
    border: 0;
    margin: 5px 10px;
  }

  .react-calendar__month-view__days__day {
    height: 30px;
    font-size: 12px;
  }

  .react-calendar__month-view + .react-calendar__month-view {
    border-left: 1px solid #edefff;
    margin: 0;
    padding: 10px
  }

  .x-dd {
    transform: translateX(-35%);
    margin-top: 15px;
    display: inline-block;
    background: #fff;
    position: relative;
    border: 1px solid #f2f2f2;
    box-shadow: rgba(0, 0, 0, 0.12) 0 10px 20px, rgba(0, 0, 0, 0.05) 0 -6px 10px;
    border-radius: 10px;
    padding: 5px 8px;
   &.single{
     .react-calendar{
       width: 240px;
       .react-calendar__navigation__prev2-button,
       .react-calendar__navigation__next2-button,
       .react-calendar__navigation__label__divider {
         display: block;
         margin:  0 3px;
       }
     }

   }
    &:after {
      content: '';
      display: block;
      position: absolute;
      border: 6px solid transparent;
      border-top: 0;
      border-bottom-color: #ffffff;
      top: -6px;
      left: 50%;
      margin-left: -6px;
    }

    & > div {
      display: flex;
    }

    & > div + div {
      justify-content: space-between;
    }

    .btn {
      &:hover {
        opacity: .9;
      }

      &:before {
        content: '';
        position: absolute;
        left: -20px;
        right: -20px;
        top: -30px;
        height: 40px;
        background: rgba(255, 255, 255, 0.15);
        border-radius: 50%;
      }

      overflow: hidden;
      position: relative;
      font-size: 12px;
      cursor: pointer;
      width: 50px;
      margin: 3px;
      border-radius: 4px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      background: linear-gradient(#61b3f0, #228af6);
      box-shadow: rgba(34, 138, 246, 0.3) 0 3px 6px;
    }
  }
}
