* {
  font-family:  "Microsoft Yahei", sans-serif, arial;
  box-sizing: border-box;
}
li {
  list-style: none;
}
body, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre, code, form, fieldset, legend, input, button, textarea, p, blockquote, th, td {
  margin: 0;
  padding: 0;
}

body, html, #root {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  color: #555;
}

a {
  color: #484848;
  text-decoration: none;
}

a:hover {
  color: #55c3fb;
}

a.disable {
  color: #999;
}

a.disable:hover {
  text-decoration: none;
  cursor: default;
}


.input {
  width: 268px;
  height: 26px;
  padding: 0 5px;
  border: 1px solid #C1BBC7;
  line-height: 26px;
  font-size: 12px;
  color: #535471;
  background: #FFFFFF;
  border-radius: 2px;
  transition: all .2s;
}

.input:hover {
  border-color: #26B999;
  box-shadow: 0 0 3px #26B999;
}

.input:focus:hover {
  border-color: #A9A9B8;
  color: #555;
  box-shadow: 0 1px 3px rgba(120, 198, 116, 0.2) inset;
}

.input.err, .input.err:hover {
  border-color: #FF819C;
  background-color: #FFF7F9;
  box-shadow: 0 1px 3px rgba(255, 129, 156, 0.2) inset;
}

.input:focus {
  border-color: #A9A9B8;
  color: #555;
  box-shadow: 0 1px 3px rgba(169, 169, 184, .5) inset;
  outline: none;
}


@import "../assets/font/stylesheet.css";
@import "./anim";

@mixin _scroll() {
  &::-webkit-scrollbar-track {
    border-radius: 3px;
    background-color: rgba(245, 245, 245, 0.1);
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(245, 245, 245, 0.06);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: rgba(85, 85, 85, 0.8);
  }
}

#root {
  background: #fff;
  z-index: 1;
  padding: .1px 0;
  position: relative;
  transition: background .3s ease-in-out;
  min-width: 1240px;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

* {
  @include _scroll();
}

.container {
  width: 1240px;
  margin: 0 auto;
}

.x-loading {
  z-index: 10;
  &.fixed {
    position: fixed;
    z-index: 100;
  }

  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  display: none;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  align-items: center!important;
  justify-content: center!important;
  background: rgba(255, 255, 255, 0.5) !important;

  &.a, &.b {
    display: flex;
  }

  &.a.b {
    opacity: 1;
  }

  .in {
    min-width: 200px;
    min-height: 70px;
    padding: 20px;
    display: inline-flex;
    background: #fff;
    align-items: center;
    box-shadow: rgba(0, 0, 0, .2) 0 10px 20px;
    border-radius: 3px;
    position: relative;
    top: -5%;
    justify-content: center;

    & > div:first-child {
      i {
        background: none;
        display: block;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        border: 2px solid #0A94E3;
        border-right-color: transparent;
        border-bottom-color: transparent;
        margin-right: 12px;
        animation: rotateZ360 .5s .2s linear both infinite;

        i {
          position: relative;
          top: -2px;
          left: -2px;
          margin: 0;
          animation: rotateZ-360 1.3s linear both infinite;
        }
      }
    }
  }
}

table.report-table {
  color: #666;
  width: 100%;
  border-collapse: collapse;
  position: relative;
  thead {
    transform: translate3d(0,0,0);
    border: 1px solid #dedede;
    position: relative;
    z-index: 0;
  }

  a {
    padding: 0 5px;
  }
  tbody{
    position: relative;
    z-index: 3;
    tr:hover {
      position: relative;
      z-index: 3;
      background: #448af5;
      transform: translate3d(0,0,10px);
      box-shadow: #448af5 0 2px 18px;
      outline: #448af5 solid 10px;
      border-color: #346df5;
      td{
        color: #fff;
        border-top: 1px solid #448af5;
        a{
          color: #fff;
        }
      }
    }
  }

  tr + tr {
    border-top: 1px dashed #dedede;
  }

  td {
    text-align: center;
    height: 50px;

    & > div {
      height: inherit;
      display: flex;
      white-space: nowrap;
      align-items: center;
      justify-content: center;
      font-size: 12px;
    }
  }

  th {
    height: 52px;
    color: #262732;
    background-color: #f4f7f8;
    padding: 10px 0;
    font-weight: 400;

    & + th {
      position: relative;

      &:before {
        position: absolute;
        left: 1px;
        top: 20px;
        height: 10px;
        content: '';
        display: block;
        width: 1px;
        background: #dadada;
      }
    }
  }
}

div::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.02);
  background-color: rgba(255, 255, 255, 0.1);
}

div::-webkit-scrollbar
{
  width: 4px;
  background-color: #F5F5F5;
}

div::-webkit-scrollbar-thumb
{
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
@keyframes bubble {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  30% {
    opacity: .7;
    transform: translate3d(5px, -10px, 0) scale(.8);
  }
  60% {
    opacity: .5;
    transform: translate3d(10px, -15px, 0) scale(.4);
  }
  100% {
    opacity: 0;
    transform: translate3d(13px, -20px, 0) scale(.1);
  }
}

@keyframes bubble1 {
  0% {
    opacity: 1;
    transform: translate3d(3px, 5px, 0);
  }
  30% {
    opacity: .8;
    transform: translate3d(8px, -20px, 0) scale(.8);
  }
  60% {
    opacity: .5;
    transform: translate3d(14px, -10px, 0) scale(.3);
  }
  100% {
    opacity: 0;
    transform: translate3d(17px, -18px, 0) scale(0);
  }
}

.full-page{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  min-height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  &>.full{
    flex: 1;
  }
}

@import "../assets/images/logo/logs";

@each $name in $lotteries {
  .c-#{$name}:before {
    background-image:url("../assets/images/logo/#{$name}.png") !important;
  }
}

.vip-btn {
  position: relative;
  width: 161px;
  height: 60px;
  border-radius: 30px;
  padding-left: 78px;
  line-height: 60px;
  box-shadow: rgba(25, 172, 249, 0.4) 1px 4px 20px -7px;
  background: linear-gradient(90deg,#f8f8f8,#fff);
  margin: 0 10px 20px 0;
  cursor: pointer;
  transition: .1s ease-in-out;
  overflow: hidden;
  span{
    position: relative;
    z-index: 2;
  }
  &:before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(90deg,#61a7fa,#3079f5);
    opacity: 0;
    transition: .2s ease-in-out;
  }
  &.act,&:hover{
    &:before{
      opacity: 1;
    }
    box-shadow: rgba(25, 172, 249, 0.8) 1px 4px 20px -7px;
    color: #fff;
  }

  &:after{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right:0;
    bottom: 0;
    background: url("../assets/images/new/v0.png") no-repeat 20% 0;
    background-size: 26%;
  }
  @for $i from 1 through 10 {
    &.v-#{$i}:after {
      background-image: url("../assets/images/new/v#{$i}.png");
    }
  }
  &.v-10:after{
    background-size: 31%;
  }
}
.l-card ,.s-card{
  position: relative;
  .msk{
    z-index: 8;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(0, rgba(88, 182, 248, .64), rgba(48, 121, 245, .87));
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    padding: 40px 30px;
    transition: .2s ease-in-out;
    transform: translateY(100%);
    div{
      transition: .1s ease-in-out;
      width: 155px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 4px;
      height: 40px;
      background: rgba(252, 252, 252, .8);
      opacity: .9;
      color: #77aff2;
      &:hover{
        opacity: 1;
        background: #fff;
        color: #79b4f9;
      }
    }
  }
}
