@import "../../styles/anim";
.cenPage{
  .si{
    display: flex;
    margin:  20px 40px 0;
    .r+.r{
      border-top: 1px solid #e7e7e7;
    }
    .a{
      &+div{
        display: flex;
        flex-direction: column;
        flex: 1;
        border-radius: 0 10px 10px 0;
        border: 1px solid #e9f0f5;
        border-left: none;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      width: 170px;
      height: 139px;
      border-radius: 10px 0 0 10px;
      color: #fff;
      font-size: 16px;
      background: #95a2ff;
    }
    .dt{
      position: absolute;
      bottom: 100%;
      background: #fff;
      margin-bottom: 20px;
      box-shadow: rgba(0, 0, 0, .1) 0 10px 24px -5px;
      border: 1px solid #e7e7e7;
      padding: 20px;
      right: 0;
      left: 0;
      display: none;
      color: #666;
      &:after{
        content: '';
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        bottom: -5px;
        left: 50%;
        background: #fff;
        border: inherit;
        border-right: 0;
        border-top: 0;
       transform: rotateZ(-45deg);
      }
      span{
        background: #fff!important;
        justify-content: flex-end;
        flex: 1;
        padding: 0!important;
      }
      div{
        height: 27px;
        display: flex;
        align-items: center;
      }
    }
    .r{
      flex: 1;
      margin: 0;
      padding: 0;
      .v{
        position: relative;
        color: #377ef6;
        cursor: pointer;
        &:hover{
          color: #0d9fff;
          text-decoration: underline;
          .dt{
            display: block;
          }
        }
      }
      span{
        &:nth-child(2n){
          background: #f7f7f7;
        }
        height: 100%;
        display: flex;
        align-items: center;
        position: revert;
        flex: 1;
        padding:  0 30px;
        &+span{
           border-left: 1px solid #e7e7e7;
        }
      }
    }
  }
  .info{
    .btn{
      height: 32px;
      margin: 40px 78px;
      border-radius: 50px;
    }
    margin: 40px;
    p{
      padding: 0 10px;
      color: #ccc;
    }
    .r{
      height: 56px;
      input{
        outline: none;
        width: 218px;
        height: 35px;
        border-radius: 8px;
        border: solid 1px #d8dcd6;
        background: #ffffff;
        box-shadow: inset 2px 2px 3px rgba(192,192,192,0.5);
        padding:  0 10px;
        transition: .3s;
        &:focus{
          border-color: #0d9fff;
        }
      }
      .x-dates{
        padding: 0 5px;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        span{
          flex: 1;
          padding:  0 5px;
        }
        width: 169px;
        height: 35px;
        border-radius: 8px;
        border: solid 1px #dedede;
        background: #ffffff;
      }
       label{
         width: 75px;
         text-align: right;
         margin-right: 10px;
       }
    }
  }
  .bx{
    width: 946px;
    height: 96px;
    border-radius: 16px;
    background: rgba(226,228,237,0.3);
    margin: 10px;
    display: flex;
    align-items: center;
    padding:  0 15px;
    .if{
      flex: 1;
    }
    label{
      color: #636363;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: 1.2px;
    }
    .bt{
      &.f{
        background: #74ad7c;
        box-shadow: rgba(116, 173, 124, 0.5) 0 8px 10px -5px;
      }
      width: 143px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      transition: .2s ease-in-out;
      background: #5a91f0;
      cursor: pointer;
      &:hover{
        opacity: .8;
      }
      box-shadow: rgba(55, 126, 246, 0.4) 0 8px 10px -5px;
      border-radius: 20px;
    }
    p{
      padding-top: 5px;
      color: #a4a4a4;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.9px;
    }
    i{
      background: url("./img/0.png");
      width: 62px;
      height: 62px;
      margin:  0 13px;
    }
    .b{
      background-image: url("./img/1.png");
    }
    .c{
      background-image: url("./img/2.png");
    }
    .d{
      background-image: url("./img/3.png");
    }
  }
  .mn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding:  20px 0;
    .r{
      padding: 0;
    }
    label{
      display: flex;
      align-items: center;
      margin: 5px;
      &:before{
        margin:  0 5px;
        content: '';
        border: 4px solid #fadfb3;
        display: block;
        border-radius: 50%;
        width: 0;
        height: 0;
      }
      span{
        color: orange;
      }
      .ch{
         display: flex;
        background: #ec7c3b;
        color: #fff;
        border-radius: 10px;
        padding:  0 10px;
        height: 23px;
        cursor: pointer;
        align-items: center;
        &:hover{
          opacity: .8;
        }
      }
    }
  }
  .tip{
    display: inline-flex;
    align-items: center;
    margin:  0 30px;
    height: 24px;
    border-radius: 5px;
    background: #fce8e8;
    color: #fd7373;
    padding: 0 10px;
  }
}
.status-ball{
  position: relative;
  span{
    color: #fff;
    bottom: 20px;
    font-weight: 200;
    position: absolute;
    font-size: 25px;
    left: 47px;
    right: 50px;
    text-align: center;
  }
  i{
    border-radius: 50px;
    display: block;
    border: 3px solid;
     position: absolute;
    animation: 5s float both linear infinite;
  }
  .a{
    left: 20px;
    top: 80px;
    border-width: 6px;
    animation-delay: 3s;
    animation-duration: 4s;
  }
  .b{
    left: 12px;
    top: 50px;
    border-width: 4px;
    animation-delay: 1s;
    animation-duration: 3s;
  }
  .c{
    left: 24px;
    top: 10px;
    border-width: 3px;
    animation-delay: .4s;
    animation-duration: 6s;
  }
  .d{
    left: 150px;
    top: 20px;
    border-width: 3px;
    animation-duration: 6s;
  }
  .e{
    left: 170px;
    top: 90px;
    border-width: 5px;
    animation-delay: .3s;
    animation-duration: 3s;
  }
  display: flex;
  align-items: center;
  width: 200px;
  svg{
    width: 130px;
    height: 130px;
    margin:  0 auto;
  }
  .rot{
    transform-origin:63px 69px;
    animation: 5s rotateZ360 both linear infinite;
  }
}
