.full-page.app{
  i{
    display: block;
  }
  .bg0{
    background: url("../../assets/images/new/ab.jpg") top center;
    background-size: cover;
  }
  .a{
    margin-top: 30px;
    margin-right: 100px;
    display: flex;
    width: 252px;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    line-height: 2;
    span{
      color: #377ff8;
      font-size: 14px;
    }
    p{
      color: #c2c2c2;
      &+span{
        font-size: 13px;
      }
    }
  }
  .inf{
    padding: 10px;
    letter-spacing: 2px;
    margin: 152px 80px;
    width: 719px;
    &>span{
      font-size: 14px;
      color: #b2b2b2;
      display: block;
      margin-top: 30px;
    }
    &>div{
      display: flex;
    }
    h1{
      color: #468bfb;
      font-size: 30px;
      margin-bottom: 26px;
      text-align: left;
      &+p{
        font-size: 18px;
        color: #323232;
        margin-bottom: 20px;
        &+span{
          line-height: 2;
          font-size: 14px;
          width: 512px;
        }
      }

    }
  }
  .qr,.h5 {
    padding: 10px;
    border: 1px solid #f2f2f2;
    background: #fff url(../../assets/images/new/q.png);
    width: 150px;
    height: 150px;
    border-radius: 4px;
    background-size: 100% 100%;
    margin-bottom: 15px;
  }
  .h5{
    background: transparent url(./img/w.png);
    width: 133px;
    height: 146px;
    border: none;
  }
}
