.sign-box {
  box-shadow: rgba(0, 0, 0, .5) 0 5px 40px -10px;
  overflow: hidden;
  border-radius: 10px;
  height: 640px;
  width: 380px;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding-bottom: 42px;

  .tb {
    position: relative;
    justify-items: center;
    align-items: center;
     display: flex;
    width: 240px;
    border-radius: 100px;
    border: 1px solid #eee;
    box-shadow: rgba(0,0,0,.1) 0 2px 4px;
    margin:  -15px auto 30px ;
    div:not(.ac){
      display: flex;
      padding-bottom: 5px;
      align-items: center;
      justify-content: center;
      height: 44px;
      font-size: 13px;
      flex: 1;
      cursor: pointer;
      color: #999;
     &:hover, &.act{
        color: #000;
      }
    }
     .ac{
       left: 30px;
       bottom: 8px;
       position: absolute;
       height: 4px;
       border-radius: 4px;
       width: 60px;
       background: linear-gradient(90deg,#b74079,#586ccc);
       transition: .3s ease-in-out;
       &._1{
         width: 80px;
         transform: translate3d(110px,0,0);
       }
     }
  }

  .btn {
    cursor: pointer;
    height: 48px;

    &.dis {
      pointer-events: none;
      opacity: .7;
    }

    &:hover {
      opacity: .9;
    }
  }

  &.s-tel {
    height: 630px;
  }
&.s-usr{
  height: 630px;
}
  &.s-reg {
    height: 540px;
  }

  &.s-reg-f {
    height: 620px;

    .ctx {
      z-index: 6;
      margin-top: 0;
    }
  }

  .hd {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .bk {
    display: inline-flex;

    &:hover {
      opacity: 1;
    }

    transition: .3s ease-in-out;
    font-size: 13px;
    margin: 10px;
    color: #14293f;
    opacity: .5;
    cursor: pointer;

    i {
      background: url("./img/b.svg") no-repeat center;
      background-size: 50%;
      width: 20px;
      height: 20px;
    }
  }

  .btn {
    padding: 0 30px;
    white-space: nowrap;
    background: linear-gradient(90deg, #3f6beb, #2f7dff);
    color: #fff;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sp-link {
    background: #f7fbff;
    border: 1px solid #e7e9ee;
    width: 100%;
  }

  .ss {
    flex: 1;
  }

  .sign-ft {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    z-index: 0;
    height: 100px;
    overflow: hidden;

    i {
      z-index: 4;
      background: linear-gradient(#7e30f8, #a972fd);
      transform: rotate(35deg);
      transform-origin: left top;
      position: absolute;
      display: block;
      height: 500px;
      width: 500px;
      left: -10%;
      top: -20%;
    }

    .i1 {
      z-index: 2;
      background: linear-gradient(#2f8fff, #5aa8ff);
      left: 85%;
      top: 30%;
      transform: rotate(70deg) skewY(40deg);
    }

    .i2 {
      z-index: 1;
      left: 100%;
      top: 5%;
      background: linear-gradient(#3c6df8, #4c7bff);
      transform: rotate(55deg);
    }
  }

  i {
    display: block;
  }

  .ti {
    text-align: center;

    div {
      line-height: 3;
      font-size: 12px;
      color: #d1d1d4;
      text-align: center;
    }

    span {
      letter-spacing: 3px;
      font-weight: 600;
      font-size: 20px;
      color: #333;
      display: block;
      margin-top: 20px;
    }

    i {
      margin: 30px auto 10px;
      border-radius: 50%;
      width: 90px;
      height: 90px;
      display: block;
      background: url("./img/o.png") no-repeat center;
      background-size: 90% auto;
    }
  }
  .w-ipt {
    margin-bottom: 5px;
    display: flex;
    height: 45px;
    border-bottom: 1px solid #e7e9ee;
    align-items: center;
    position: relative;
    padding: 0 15px;

    .pf {
      width: 70px;
      display: flex;
      align-items: center;

      p {
        position: relative;
        right: -3px;
        margin: 0;
        font-size: 13px;
        color: #999;
        z-index: 9;
      }
    }

    .captcha {
      width: 90px;
      margin: -5px -17px 0 0;
      height: 34px;

      &:hover img {
        bottom: 113%;
        width: 165px;
        opacity: 1;
        right: 0;
        top: auto;
        border: 1px solid #334eb3;
        background: #ddd;
      }

      img {
        top: auto;
        right: 0;
        bottom: 0;
      }
    }

    s {
      flex: 1;
      display: block;
      height: 60px;
      background: url("./img/4.svg") no-repeat center;
      background-size: 40% auto;
      opacity: .3;
    }

    i {
      background: #3b69ff no-repeat center;
      border-radius: 50%;
      height: 24px;
      width: 24px;
      background-size: 66%;
    }

    span {
      font-size: 12px;
      position: absolute;
      pointer-events: none;
      left: 89px;
      right: 0;
      background: white;
      top: 5px;
      bottom: 5px;
      color: #ff9900;
      display: flex;
      align-items: center;
      padding: 5px;
    }

    .sco {
      height: 30px;
      margin: 0 -10px;
      font-size: 12px;
      width: 80px;
    }
  }
  .tel{
    .pf{
      width: 74px;
      position: relative;
      left: -3px;
      padding: 3px;
      border-radius: 100px;
      border: 1px solid #3b69ff;
      &:after{
        position: absolute;
        right: 3px;
        top: 14px;
        content: '';
        display: block;
        border: 3px solid transparent;
        border-top-color: #3b69ff;
        width: 0;
        height: 0;
      }
    }
    &.er{
      .pf{
        border-color: orange;
      }
    }
  }
  .cap {
    span {
      right: 70px;
    }
  }

  .er {
    border-bottom-color: #ff9900;

    i {
      background-color: #ff9900;
    }
  }

  .tel i {
    background-image: url("./img/0.svg");
  }

  .usr i {
    background-image: url("./img/1.svg");
  }

  .pwd i {
    background-image: url("./img/2.svg");
  }

  .cap i {
    background-image: url("./img/3.svg");
  }

  input {
    color: #14293f;
    outline: none;
    border: none;
    width: 0;
    padding: 0 3px;
    font-size: 13px;
    height: 42px;
    flex: 1;

    &:focus {
      + span {
        display: none;
      }
    }
  }

  .extra {
    font-size: 13px;
    padding-top: 10px;
    width: 70%;
    text-align: center;
    color: #657889;
    margin: 0 auto 30px;
    line-height: 30px;

    a {
      cursor: pointer;
      font-size: 12px;
      text-decoration: underline;
      position: relative;
      top: 20px;
      color: #233a5c;
    &+p{
      font-size: 12px;
      color: #aaa;
      padding-top: 13px;
    }
      span {
        color: red;
        text-decoration: underline;
      }
    }

    .te {
      background: linear-gradient(90deg, #da15ec, #9146fc);

      i {
        border-radius: 50%;
        display: block;
        margin-right: 15px;
        background: #fff;
        width: 30px;
        height: 30px;
        background: url("./img/5.svg") no-repeat center;
        background-size: 60%;
      }
    }
  }

  .ctx {
    width: 74%;
    margin: 30px auto 0;
  }

  .clo {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: .3s ease-in-out;
    color: #e0e0e0;
    border-radius: 50%;
    border: currentColor 2px solid;
    justify-content: center;

    &:hover {
      color: #14293f;
    }

    s {
      display: block;
      width: 50%;
      height: 2px;
      background: currentColor;
    }
  }
}


.pwd-opt {
  margin: -10px 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;

  * {
    cursor: pointer;
    color: #2c4868;
  }

  .fgw {
    color: #ee1a2c;
  }

  div {
    text-decoration: underline;
  }

  label {
    align-items: center;
    display: flex;
  }

  .checkbox {
    display: block;
    position: relative;
    width: 14px;
    margin-right: 4px;
    height: 14px;
    border-radius: 50%;
    border: 1px solid #e7e9ee;

    &:before {
      content: '';
      position: absolute;
      left: 2px;
      right: 2px;
      top: 2px;
      bottom: 2px;
      border-radius: 50%;
      background: transparent;
    }

    &.ck {
      &:before {
        background: #2c4868;
      }
    }
  }
}