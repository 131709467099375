.pdd-msk{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,.2);
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
  transition: .3s .1s ease-in-out;
  &.so{
    pointer-events: all;
    z-index: 999;
    opacity: 1;
  }
  p{
    font-size: 18px;
    color: #343434;
    line-height: 1.6;
  }
  .fi{
    font-size: 13px;
    margin: 15px 0;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      margin-right: 10px;
      background: url("./img/pdf.svg") no-repeat center;
      background-size: contain;
      width: 50px;
      height: 60px;
    }
  }
  i{
    display: block;
  }
  .sv{
    position: absolute;
    bottom: 10px;
    right: 0;
    top: 0;
    pointer-events: none;
    width: 160px;
    background:bottom right url("./img/sv.png") no-repeat;
    background-size: contain;
  }
  &>div{
    padding: 20px;
    width: 380px;
    background: transparentize(#fff,.1);
    backdrop-filter: blur(10px);
    position: relative;
    border-radius: 3px;
    box-shadow: rgba(0,0,0,.4) 0 10px 30px -7px;
  }
  .bns{
    *{
      text-align: center;
      border-radius: 4px;
      font-size: 13px;
      padding: 8px 20px;
      display: block;
      margin-top: 10px;
      cursor: pointer;
      width: 180px;
      background: transparentize(#000,.9);
      opacity: .8;
      &:hover{
        opacity: 1;
      }
    }
    a{
      background: #3371a1;
      color: #fff;
    }
  }
}