.time-num {
  width: 33px;
  height: 53px;
  position: relative;
  border-radius: 3px;
  font-weight: 200;
  border-left: 0;
  border-top: 0;
  &:first-child{
    margin-left: 0!important;
  }
  [data-n]{
    color: transparent;
    background: url("../../assets/images/bet/nm.svg") no-repeat;
    background-size: auto 90%;
    height: 0;
    box-sizing: content-box;
    padding-top: 184%;
    margin: 0;
  }
  [data-n="0"]{
    background-position: 0% 50%;
  }
  [data-n="1"]{
    background-position: 9.5% 50%;
  }
  [data-n="2"]{
    background-position: 19.9% 50%;
  }
  [data-n="3"]{
    background-position: 29.9%  50%;
  }
  [data-n="4"]{
    background-position: 39.9% 50%;
  }
  [data-n="5"]{
    background-position: 49.7%  50%;
  }
  [data-n="6"]{
    background-position: 59.6%  50%;
  }
  [data-n="7"]{
    background-position: 69.6%  50%;
  }
  [data-n="8"]{
    background-position: 79.6% 50%;
  }
  [data-n="9"]{
    background-position: 89.5% 50%;
  }
  & > div {
    height: 50%;
    position: relative;
    overflow: hidden;
    div {
      transform-style: preserve-3d;
      perspective: 100px;
      perspective-origin: 40% 50%;
      position: absolute;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 200%;
      transform-origin: center;
      color: #fff;
      left: 0;
      top: 0;
      text-align: center;
      font-size: 30px;
      line-height: 1;
    }
  }
  .b {
    user-select: none;
    pointer-events: none;
    border-radius: 0 0 3px 3px;
    div{
      top: -100%;
      margin-top: -1px;
    }
    .n {
      transform: rotateX(-90deg);
    }
  }
  &.ani {
    .a {
      .n {
        animation: rA .2s both 1 ease-in-out;
      }
    }

    .b {
      .n {
        animation: rB .2s .3s both 1 ease-in-out;
      }
    }
  }
}
.left-time{
  display: flex;
  align-items: center;
  height: 100%;
  float: right;
  .time-num{
    margin-left:3px;
    &:nth-child(2n+1){
      margin-left:16px;
      &:before{
        content: '';
        background: url(../../assets/images/bet/nm.svg) no-repeat 95% 50%;
        background-size: auto 100%;
        display: block;
        width: 10px;
        height: 90%;
        position: absolute;
        left: -12px;
        top: 0;
      }
    }
    &:first-child:before{
      content: none;
    }
  }
}
