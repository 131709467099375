$w: 800px;
.tv-ti {
  margin: 60px 0 40px !important;
  text-align: center;
}

.tv-ws {
  height: inherit;
  display: flex;
  overflow: auto;
  padding: 10px 0 4px;
  align-items: center;
  justify-content: space-around;

  div {
    height: 100%;
    box-shadow: rgba(0, 0, 0, .1) 0 2px 5px -2px;
    background: linear-gradient(#fff, #f2f2f2);
    border-radius: 5px;
    cursor: pointer;
    margin: 0 5px;
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border: 1px solid #eee;

    &:hover {
      background: #fff;
      border-color: #aaa;
    }
  }

  i {
    display: block;
    width: 33px;
    height: 33px;
    background-size: contain;
    background-position: center;
  }
}

.stv-bg {
  margin: 1px;
  border-radius: 7px;
  background: #eee url("../../assets/images/new/8.png") center repeat;
  border: 3px solid #fff;
  padding: 10px 20px;
  top: -2px;
}

.sport-tv {
  box-shadow: rgba(0, 0, 0, .1) 0 3px 20px -4px;
  border-radius: 8px;
  position: relative;
  width: 1240px;
  margin: 10px auto;
  background: linear-gradient(90deg, #eee, #f7f7f7, #fafafa, #eee 5%, #eee 95%, #fafafa, #eee);
  overflow: hidden;
  height: 0;
  transition: .3s ease-in-out;
  font-size: 13px;
  display: flex;
  flex-direction: column;

  .stu div {
    display: flex;
    align-items: center;

    i {
      border-radius: 50%;
      display: block;
      margin-right: 3px;
      height: 5px;
      width: 5px;
      background: #0c9264;
    }

    color: #888;
    font-size: 12px;
  }

  .tm {
    text-align: center;
    font-size: 10px;
    position: relative;
    padding-bottom: 5px;
  }

  &.act {
    height: 641px;
  }

  .ctx {
    flex: 1;
    display: flex;
  }

  .ply {
    display: flex;
    width: $w;
    flex-direction: column;

    .be {
      display: flex;
      align-items: center;
      height: 80px;
    }

    .bn {
      width: 80px;
      height: 40px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #0a94e3;
      margin: 0 30px;
    }

    .ls {
      height: inherit;
      flex: 1;
    }

    iframe {
      border-radius: 5px;
      background: #000;
      flex: 1;
      width: 100%;
      border: 0;
    }
  }

  .sub {
    position: relative;
    height: 529px;
    flex: 1;
    overflow: auto;
  }

  .suc {
    &.act, &:hover {
      background: #fff;
      border-color: #aaa;
    }
    &.act{
      box-shadow: rgba(0,0,0,.1) 0 2px 6px -2px;
      .su-t{
        &>span{
          font-weight: 600;
          color: #000;
        }
      }
    }
    cursor: pointer;
    background: linear-gradient(#fff, #f2f2f2);
    border-radius: 5px;
    margin: 10px;
    border: 1px solid #ddd;
    padding: 5px 10px;
  }

  .su-t {
    font-size: 12px;
    padding-bottom: 9px;
    span {
      flex: 1;
    }
  }

  .su-t, .su-m {
    align-items: center;
    display: flex;
  }

  .su-m {
    justify-content: space-between;
  }

  .g {
    width: 150px;
    align-items: center;
    display: flex;
    justify-content: space-between;

    span {
      flex: 1;
      font-size: 11px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0 5px;
    }

    p {
      padding: 0 3px;
    }

    & + b + .g {
      span {
        text-align: right;
      }
    }

    i {
      display: block;
      width: 20px;
      height: 20px;
      background: no-repeat center;
      background-size: contain;
    }
  }
}

.stv-h {
  height: 80px;
  display: flex;

  .stc-t {
    width: $w;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &>span{
      position: relative;
      top: -3px;
      color: #b0b0b0;
      font-size: 12px;
      letter-spacing: 2px;
      line-height: 1px;
    }
    .g {
      width: auto;

      p {
        font-weight: 600;
        padding: 0 10px;
        font-size: 24px;
      }

      span {
        font-size: 16px;
        padding: 0 10px;
      }

      i {
        margin: 0 5px;
        width: 40px;
        height: 40px;
      }
    }
  }

  .bns {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    div {
      box-shadow: rgba(0, 0, 0, .3) 0 3px 8px -3px;
      margin: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50px;
      color: #666;
      cursor: pointer;
      background: linear-gradient(#fff, #f7f7f7);
      width: 104px;
      height: 38px;

      &.act, &:hover {
        color: #fff;
        background: linear-gradient(#45c8ff, #08b8ff);
      }
    }
  }
}
