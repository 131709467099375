@import "../../styles/util";

.pg-foot {
  background: #121319;
  height: 300px;
  font-size: 12px;
  min-width: 1240px;

  li {
    box-sizing: content-box;
  }

  .container {
    &:after {
      clear: both;
      content: '';
      display: block;
    }
  }

  .footTitle {
    li {
      float: left;
      width: 24%;
      padding-left: 1%;
      font-size: 14px;
      color: #666;
      border-bottom: 1px #3c3c3c dashed;
      line-height: 66px;

      i {
        background: g1Url("sd_logo_s.png") no-repeat center center;
        width: 25px;
        height: 13px;
        padding-right: 36px;
      }
    }
  }

  .footCont {
    height: 240px;

    li {
      float: left;
      width: 24%;
      padding-left: 1%;
      color: #666;
      height: 120px;
      padding-top: 20px;

      a:hover {
        color: #85735b;
      }
    }

    .brand {
      background: g1Url("foot_logo.png") no-repeat 14px 40px;
    }

    .lgs {
      background: g1Url("foot_logo2.png") no-repeat center center;
      width: 40px;
      height: 130px;
      float: left;
    }

    .contact {
      li {
        width: 75%;
        height: 43px;
        font-size: 12px;
        padding-top: 2px;
      }

      .info {
        line-height: 36px;
      }
    }

    p {
      text-indent: 2em;
    }

    .more {
      border: 1px solid #28282e;
      float: right;
      padding: 3px 6px;
      color: #8e8e8e;
      margin-top: 50px;

      &:hover {
        background-color: #394c71;
        color: #fff;
        transition: .4s;
      }
    }

    .dot {
      li {
        list-style: square;
        height: 16px;
        float: initial;
        padding-top: 2px;
        padding-bottom: 18px;
        width: 95%;
        margin-left: 5%;
      }

      a {
        color: inherit;
      }
    }
  }

  .copy {
    height: 30px;
    line-height: 30px;
    margin-left: 15px;
    font-size: 14px;

    .link {
      color: #85735b;
      font-size: 12px;
      cursor: default;
    }
  }
}


.b1-foot {
  // background: g1Url("foot-b1.png") no-repeat center center;
  background-color: #2f2f2f;
  // height: 182px;
  font-size: 12px;
  min-width: 1240px;
  .b1container{
    width:1240px;
    margin:0 auto;
    height: inherit;
    padding: 20px 0 0;
      .b1ca{
        text-align: center;
        font-size: 14px;
        letter-spacing: 1px;
        color: #5b5a5a;
        padding:12px;
      }
      .b1cb{
        width:515px;
        height:40px;
        margin:10px auto 10px;
        background: g1Url("foot-icon_02.png") no-repeat center center;
      }
      .b1cc{
        width:400px;
        height:35px;
        margin:0 auto;
        &>span{
          display: inline-block;
          text-align:center;
          // border-right:1px solid #9a9a9a;
          color:#9a9a9a;
          cursor: pointer;
          font-size:12px;
          position: relative;
          margin-right: 10px;
          padding-right: 8px;
          // letter-spacing: 2px;
        }
        &>span>a{
          color:#9a9a9a;
        }
        &>span:after{
          content:'';
          width:1px;
          height:10px;
          position: absolute;
          right:0px;
          top:4px;
          // background:#9a9a9a;
          background:#9a9a9a;
        }
        &>span:nth-last-child(1):after{
         display: none;
        }
      }
  }
  .b1cd{
    background-color: #242424;
    height:40px;
    line-height: 40px;
  }
  .b1cd_wrap{
    width: 760px;
    margin: 0px auto;
    .b1cd_l{
      float:left;
    }
    .b1cd_r{
      float:right;
    }
  }
    // &>div{
    //   display: flex;
    //   align-items: center;
    // }
}

.b2-foot {
  height: 180px;
  font-size: 12px;
  min-width: 1240px;
  background:linear-gradient(90deg,#f9f9f9,#efefef);
  .b2container{
    width:1240px;
    height:100%;
    margin:0 auto;
    overflow: hidden;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // height: inherit;
    &>div{
      width:20%;
      height:100%;
      float:left;
    }
    .b2child{
      text-align: center;
      .b2c_a{
        width: 62px;
        height: 62px;
        // border:1px solid gray;
        margin:20px auto 10px;
        background-size: contain;
      }
      .b2c_b{
        font-size: 14px;
        color:#333;
        font-weight: bold;
        margin-bottom: 5px;
      }
      .b2c_c{
        font-size: 12px;
        color:#333;
        width: 110px;
        margin: 0 auto;
        line-height: 16px;
      }
      .b2a{
        background: g1Url("ico-b2a.png") no-repeat center center;
      }
      .b2b{
        background: g1Url("ico-b2b.png") no-repeat center center;
      }
      .b2c{
        background: g1Url("ico-b2c.png") no-repeat center center;
      }
      .b2d{
        background: g1Url("ico-b2d.png") no-repeat center center;
      }
      .b2e{
        background: g1Url("ico-b2e.png") no-repeat center center;
      }
      .b2c_a{
        background-size: contain;
      }
      &:nth-child(2n-1){
        // background:#fff;
      }
      &:nth-child(2n){
        // background:rgba(47,47,47,0.05);
        background:#efefef;
      }
    }
  }
}

