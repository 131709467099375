.lr-mask {
  transition: .3s ease-in-out;
  right: 0;
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, .5);
  z-index: 31;
  overflow: auto;
  transform: translate3d(0, 0, 0);
  font-size: 14px;
  opacity: 0;
  &.act {
    opacity: 1;
  }
  & > div {
    position: relative;
  }
}
