.v-scroll{
  transform: translate3d(0,0,0);
  user-select: none;
  position: absolute;
  border-radius: 3px;
  z-index: 10;
  transition: .3s ease-in-out;
  opacity: .7;
  &:hover{
    opacity: 1;
  }
  .vs-track,.vs-btn{
    border-radius: 10px;
    position: absolute;
    right: 0;
    bottom: 0;
    left: inherit;
    top: inherit;
    transition: .3s width,.3s height;
  }
  .vs-btn{
    background: rgba(123,145,195,.67);
    cursor: pointer;
  }
  &.hi{
    visibility: hidden;
  }
  &.horizon{
    left: 10px;
    bottom: 0;
    right: 10px;
    height: 5px;
    .vs-track{
      left: 0;
      height: 15px;
    }
    .vs-btn{
      bottom: 3px;
      height: 5px;
    }
    &:hover{
      height: 15px;
      .vs-btn{
        height: 15px;
      }
      .vs-track{
        height: 50px;
      }
    }
  }
  &.vertical{
    top: 10px;
    width: 3px;
    bottom: 10px;
    right: 0;
    .vs-track{
      top: 0;
      width: 15px;
    }
    .vs-btn{
      right:3px;
      width: 5px;
    }
    &:hover{
      width: 15px;
      .vs-btn{
        width: 15px;
      }
      .vs-track{
        width: 50px;
      }
    }
  }
}
