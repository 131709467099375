@import "../../styles/anim";
.toTop{
  position: fixed;
  z-index: 10;
  right: 5px;
  bottom: 100px;
  font-size: 10px;
  width: 83px;
  height: 169px;
  border: 1px solid #d5d5d5;
  background: #fff;
  div{
    height: 50%;
    text-align: center;
    padding-top: 60px;
    cursor: pointer;
    &:hover{
      background-color: #f2f2f2;
    }
  }
  .t{
    border-top: 1px solid #efefef;
    background: url("./img/k.png") center no-repeat;
  }
  .s{

    background: url("./img/l.png") center no-repeat;
  }
}
.full-page.lotto {
  .w0 {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    z-index: 15;

    a:hover {
      text-decoration: underline;
    }
  }

  .w01 {
    i {
      margin-right: 10px;
      display: block;
      width: 25px;
      height: 25px;
      background: #fff center no-repeat;
      border-radius: 50%;
    }

    & + div {
      border-top: 1px solid rgba(255, 255, 255, 0.25);
    }

    .i-ssc {
      background-image: url("./img/2.png");
    }

    .i-11-5 {
      background-image: url("./img/1.png");
    }

    .i-k3 {
      background-image: url("./img/0.png");
    }

    .i-pk10 {
      background-image: url("./img/4.png");
    }

    .i-dpc {
      background-image: url("./img/3.png");
    }

    transition: .2s;
    flex: 1;
    position: relative;
    display: flex;
    padding: 15px 16px;
    color: #fff;

    &:hover {
      background: #45acf5;
      i {
        opacity: 1;
      }

      .mr {
        opacity: 1;
        pointer-events: all;
      }
    }

    label {
      display: flex;
      height: 25px;
      align-items: center;
      font-size: 15px;
    }

    a {
      display: inline-flex;
      align-items: center;
      margin-right: 15px;
      font-size: 13px;
      line-height: 25px;
      color: #fff;
    }
  }

  .w02 {
    & + i {
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      width: 20px;
      height: 20px;
      margin: 0;
      background-image: url("./img/r.png");
    }
    div{
      max-width: 200px;
      overflow: visible;
      white-space: nowrap;
    }
  }
  .c-lo-nm{
    position: relative;
    z-index: 4;
    flex-direction: column;
    justify-content: center;
    display: flex;
    align-items: center;
    span{
      opacity: .9;
      color: #fff;
      font-size: 30px;
      font-weight: 200;
      text-shadow: rgba(0, 0, 0, 0.4) 01px 1px 2px;
    }
  }
  .c-lo{
    height: 96px * .8;
    z-index: 10;
    position: absolute;
    top: 64px;
    left: 32px;
    label{
      display: block;
      color: #fff;
      font-weight: 800;
      font-size: 38px;
    }
    b{
      font-size: 20px;
      margin-top: 10px;
      height: 40px;
      background: left center no-repeat;
      background-size: 100% 100%;
      min-width: 175px;
      padding: 0 30px 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .v-ssc{
      color: #84dbfd;
      background-image: url("./img/ba.png");
    }
    .v-k3{
      color: #f05280;
      background-image: url("./img/bb.png");
    }
    .v-115{
      color: #f15280;
      background-image: url("./img/bc.png");
    }
    .v-pk10{
      color: #914fea;
      background-image: url("./img/bd.png");
    }
  }

  .mr {
    transition: inherit;
    top: 0;
    position: absolute;
    left: 100%;
    width: 334px;
    background: #45acf5;
    min-height: 100%;
    padding: inherit;
    opacity: 0;
    pointer-events: none;
    a {
      width: 93px;
      padding: 0;
      margin: 0 8px 0 0;
      line-height: 30px;
      text-shadow: rgba(0, 0, 0, 0.1) 0 0 3px;
      &:nth-child(3n){
        margin: 0;
      }
    }
  }

  .full.bg0 {
    background: #f5f5f5;

    & > .container {
      padding: 60px 0 20px;
    }
  }

  .tm-filter {
    display: flex;
    margin: 30px 0 20px;

    div {
      cursor: pointer;
      border: 1px #ebebeb solid;
      background: #fff;
      width: 156px;
      height: 63px;
      margin-right: 23px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #efefef;
      }
    }

    .act {
      background: #e6e5e4;
    }
  }

  .lo-head {
    background: url("./img/bg.jpg") no-repeat;
    height: 539px;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    .lo-w {
      width: 1240px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      & > div {
        border-radius: 5px;
        height: 407px;
      }
    }

    .w0 {
      width: 280px;
      background: linear-gradient(#41a9fb, #4ed8ed);
    }

    .w1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 680px;
      padding: 0 3px;
      &>div {
        transition: .2s linear;
        width: 49.5%;
        height: 198px;
        border-radius: 3px;
        padding: 16px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        &:before,&:after{
          content: '';
          display: block;
          position: absolute;
          z-index: 10;
        }
        &:before{
          background-repeat: no-repeat!important;
          background-size: 80% auto!important;
        }
        &:after{
          border-radius: 5px;
          top: 2px;
          right: 2px;
          left: 0;
          bottom: 0;
          background: linear-gradient(-135deg,rgba(355,355,355,.7),transparent 60px);
          opacity: .8;
        }
        .rv{
          display: block;
          background: linear-gradient(45deg,rgba(255,255,255,.01),rgba(255,255,255,.1));
          width: 450px;
          height: 350px;
          border-radius: 0;
          transform-origin: 40% 30%;
          &.a{
            left: -80%;
            top: -80%;
          }
          &.b{
            left: -70%;
            bottom: -80%;
          }

          &.c{
            right: -70%;
            top: -70%;
          }

          &.d{
            right: -70%;
            bottom: -70%;
          }

        }
        span{
          font-size: 14px;
          color: #fff;
          opacity: .3;
        }
        &:hover {
          transform: translate3d(1px, -2px, 0) scale(1.01);
          box-shadow: rgba(255, 255, 255, 0.15) 0 0 20px;
        }
      }

      i {
        position: absolute;
      }
    }

    .w12, .w13 {
      margin-top: 10px;
    }

    .w2 {
      background: #fff;
      width: 273px;
      overflow: hidden;

      .rc {
        height: 125px;
        display: flex;
        flex-direction: column;
        *{
          font-size: 12px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .nod {
          padding-right: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          i {
            background: url("./img/a.png");
            width: 48px;
            height: 48px;
            display: block;
            margin: 10px;
          }
        }

        div {
          &:nth-child(2) {
            background: #f7f7f7;
          }

          font-size: 13px;
          display: flex;
          height: 33.333%;
          align-items: center;
          padding: 0 19px;
          color: #8F8F8F;

          label {
            cursor: pointer;
            width: 85px;

            &:hover {
              text-decoration: underline;
            }
          }

          a {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 10px;
            color: inherit;
            display: inline-flex;
            padding: 3px 5px;
            background: #f0f0f0;

            &:hover {
              background: #eaeaea;
            }
          }
        }
      }

      .rd {
        display: flex;
        flex-direction: column;

        .rd0 {
          display: flex;
          align-items: center;
        }

        i {
          margin-right: 10px;
          background: url("./img/b.png");
          width: 33px;
          height: 32px;
          display: block;
        }

        .rd1 {
          flex: 1;
          position: relative;

          a {
            position: absolute;
            color: #fff;
            opacity: 0;
            transition: .3s ease-in-out;
            animation: float 4s infinite;

            &:hover {
              opacity: 1 !important;
              animation-play-state: paused;
              font-size: 16px !important;
            }

            &:nth-child(1) {
              left: 16%;
              top: 55%;
              font-size: 15px;
              animation-duration: 4s;
            }

            &:nth-child(5) {
              left: 50%;
              top: 80%;
              font-size: 13px;
              animation-delay: 7s;
            }

            &:nth-child(6) {
              left: 45%;
              top: 30%;
              font-size: 12px;
              animation-duration: 7s;
            }

            &:nth-child(7) {
              left: 20px;
              top: 40%;
              font-size: 12px;
              animation-delay: 1s;
            }

            &:nth-child(8) {
              left: 40px;
              top: 10%;
              font-size: 15px;
              animation-delay: 6s;
            }

            &:nth-child(2) {
              left: 20px;
              top: 70%;
              font-size: 18px;
              animation-duration: 10s;
              animation-delay: 1s;
            }

            &:nth-child(3) {
              left: 45%;
              top: 65%;
              font-size: 14px;
              animation-delay: 3s;
            }

            &:nth-child(4) {
              left: 60%;
              top: 50%;
              font-size: 12px;
              animation-delay: 5s;
            }
          }
        }

        color: #fff;
        padding: 16px;
        height: 285px;
        background: linear-gradient(90deg, #6badfc, #68adfb);
      }
    }

    .w10 {
      &:before{
        background: url("./img/e0.png");
        width: 233px;
        height: 327px;
        right: -24%;
        top: -33%;
      }
      background: linear-gradient(90deg,rgb(77,215,237), rgb(67,65,251));
    }

    .w11 {
      &:before{
        background: url("./img/e1.png");
        width: 368px;
        height: 281px;
        right: -47%;
        top: 2%;
      }
      background: linear-gradient(0, rgb(233,87,157), rgb(245,75,105));
    }

    .w12 {
      background: linear-gradient(0, rgb(233,87,157), rgb(245,75,105));
      &:before{
        background: url("./img/e2.png");
        width: 311px;
        height: 272px;
        right: -33%;
        top: -2%;
      }
    }

    .w13 {
      background: linear-gradient(0, rgb(97,143,249), rgb(151,73,233));
      &:before{
        background: url("./img/e3.png");
        width: 337px;
        height: 207px;
        right: -46%;
        top: 10%;
      }
    }
  }

  .today-win {
    height: 119px;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;

    .ct {
      width: 1240px;
      margin: 0 auto;
      height: inherit;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .ct0{
        font-weight: 600;
        height: 34px;
        display: flex;
        align-items: center;
        b{
          font-size: 18px;
          color: #f06848;
        }
      }
      .ct1 {
        height: 50px;
        display: flex;
      }
    }
  }

  .ls {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    min-height: 500px;
  }

  .l-card {
    position: relative;
    width: 290px;
    height: 304px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-top: 1px solid #ffc027;
    margin: 20px 21px 0 4px;
    transition: .1s ease-in-out;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 10px -6px;
    overflow: hidden;
    .i-pk10~.mk {
      .n {
        padding: 0 70px !important;
      }

      b {
        width: 22px !important;
        height: 22px !important;
      }
    }
    .mk {
      position: relative;
      flex: 1;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .msk {
        background: #f8760e;
        top: 4px;

        div {
          border: 1px solid #fff;
          background: transparent;
          color: #fff;
          border-radius: 40px;

          &:hover {
            color: #f8760e;
            background: #fff;
          }
        }

      }

      & > span {
        margin: 16px 0 8px;
        font-size: 12px;
        color: #666;
      }
    }

    &:hover {
      transform: translate3d(0, -3px, 0);
      box-shadow: rgba(47, 47, 47, .3) 0 13px 24px -8px;

      .msk {
        transform: translateY(0);
      }
    }
    i {
      display: block;
    }

    & > label {
      font-size: 23px;
    }


    .inf {
      height: 55px;
      display: flex;
      color: #999;
      font-size: 12px;
      position: absolute;
      justify-content: space-between;
      bottom: 0;
      left: 0;
      right: 0;
      align-items: center;
      padding: 0 30px;

      .tm {
        display: flex;
        align-items: center;
        color: #5ebdfd;

        i {
          opacity: .7;
          margin-right: 4px;
          border-radius: 50%;
          height: 14px;
          width: 14px;
          border: 1px solid #999;

          i {
            margin: 2px 0 0 5px;
            width: 5px;
            height: 5px;
            border-radius: 0;
            border-right: 0;
            border-top: 0;
          }
        }
      }

      label {
        display: flex;

        i {
          margin-right: 4px;
          background: url(../../assets/images/new/icon/g1.png) no-repeat;
          height: 16px;
          width: 16px;
          background-size: auto 100%;
        }
      }

    }

    .n {
      width: 100%;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 0;
      flex-wrap: wrap;
      &>span{
        color: #ccc;
        font-size: 12px;
      }
      b {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        font-size: 13px;
        color: #000;
        font-weight: 200;
        background: #ededed;
        border-radius: 50%;
        margin: 2px;
      }
    }
    .i-ssc{
      background-image: url(../../assets/images/new/icon/ssc.png) ;
    }
    .i-115{
      background-image: url(../../assets/images/new/icon/115.png) ;
    }
    .i-k3{
      background-image: url(../../assets/images/new/icon/k3.png) ;
    }
    .i-pk10{
      background-image: url(../../assets/images/new/icon/pk10.png) ;
    }
    .i-3d{
      background-image: url(../../assets/images/new/icon/3d.png) ;
    }
    .i-p35{
      background-image: url(../../assets/images/new/icon/35.png) ;
    }
    & > i {
      position: relative;
      width: 88px;
      height: 88px;
      margin: 30px 0 10px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 0;

      &:before, &:after {
        content: '';
        position: absolute;
        display: block;
        background: inherit;
        background-size: cover;
      }

      &:before {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: .3;
      }

      &:after {
        left: 6px;
        right: 6px;
        top: 6px;
        bottom: 6px;
      }

    }

    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}
