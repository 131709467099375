.tBetPage{
  .inf{
    flex: 1;
    padding-left: 30px;
    color: #bbb;
  }
  .i-dt{
    width: 20px;
    height: 20px;
    border: 1px solid currentColor;
    border-radius: 50%;
    color: #B4B4B4;
    cursor: pointer;
    align-items: center;
    padding-bottom: 5px;
    &:hover{
      color: #EA8C7C;
    }
  }
  .pop-up{
    min-height: 640px;
    position: relative;
    padding:  10px;
    z-index: 10;
    background: #fff;
    &>.bn{
      float: right;
    }
    &.act{
      display: block;
    }
    .ctx{
      .hi{
        visibility: hidden;
      }
      width: 720px;
      margin:  0 auto;
    }
  }
  .l-tbs{
    margin-right: 10px;
    display: flex;
    border: 1px solid #DAE4F1;
    border-radius: 6px;
     div{
       font-size: 12px;
      display: flex;
       align-items: center;
       justify-content: center;
       width: 92px;
       height: 35px;
       color: #BACBE1;
       cursor: pointer;
       &:hover{
         background: #f1f2ff;
       }
       +div{
         border-radius:0 4px  4px 0;
         border-left:  1px solid #DAE4F1;
       }
     }
    .act{
      color: #565BB3;
      background: #E5E7FB;
    }
  }
}
