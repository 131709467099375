
.pop-wallet {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  .x-sheet {
    .btn {
      border-radius: 40px;
      background: #5a91f0;
      height: 40px;
      box-shadow: rgba(90, 145, 240, 0.5) 0 8px 20px -6px;
    }

    .x-st-h {
      z-index: 3;
      position: relative;
      padding-top: 20px;
      padding-bottom: 0;
    }

    .x-st-h, .x-tba {
      box-shadow: none;
      margin: 0;
    }
  }

  .ti {
    z-index: 11;
    display: flex;
    height: 60px;
    background: #2079f8 url("./img/bj.jpg") center no-repeat;
    color: #fff;
    align-items: center;
    font-size: 13px;

    p {
      margin-left: 20px;

      span {
        padding: 0 2px;
        font-size: 16px;
        letter-spacing: 1px;
      }
    }

    div {
      height: inherit;
      cursor: pointer;
      padding: 0 20px;
      display: inline-flex;
      align-items: center;
      position: relative;

      i {
        width: 26px;
        height: 26px;
        background: url("./img/ico.png");
        margin-right: 10px;
        display: block;
      }

      &:not(.act):after {
        content: '';
        display: block;
        position: absolute;
        right: -1px;
        width: 1px;
        height: 30px;
        top: 50%;
        background: rgba(255, 255, 255, 0.8);
        transform: translateY(-50%);
      }

      &:not(.act):hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }

    .i-0 {
      background-position: -32px 0;
    }

    .i-1 {
      background-position: 0 0;
    }

    .i-2 {
      background-position: -62px 0;
    }

    .act {
      pointer-events: none;
      background: #fff;
      color: #4080ff;

      .i-0 {
        background-position: -32px 26px;
      }

      .i-1 {
        background-position: 0 26px;
      }

      .i-2 {
        background-position: -62px 26px;
      }
    }
  }
}
