.full-page.test {
  .lk-box {
    display: flex;
    flex-direction: column;
    min-height: 300px;

    .good{
      background: green!important;
    }
    .normal{
      background: #ffb231!important;
    }
    .er,.bad{
      background: #aaa!important;
    }
    .ls{
      flex: 1;
      display: flex;
      flex-wrap: wrap;
    }
    i{
      display: block;
    }
    .ft{
      margin-top: 50px;
      display: flex;
      font-size: 13px;
      div{
        display: flex;
      }
      i{
        margin:  0 10px;
        border-radius: 50%;
        width: 18px;
        height: 18px;
      }
    }
    .way{
      justify-content: space-between;
      display: flex;
      padding: 20px;
      align-items: center;
      width: 50%;
      height: 60px;
      border: 1px solid #eee;
      font-size: 13px;
      a{
        background: #eee;
        outline: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 3px;
        padding: 10px 0;
        width: 110px;
        text-align: center;
        &:hover{
          background: #0d9fff;
          color: #fff;
        }
      }
      .bar{
        overflow: hidden;
        box-shadow: inset 0 -2px  10px rgba(0, 0, 0, 0.05);
        border-radius: 15px;
        background: #eee;
        width: 340px;
        height: 20px;
        position: relative;
        border: 1px solid rgba(0, 0, 0, 0.05);
        div{
          transform: translate3d(0,0,0);
          transition: 1s linear transform, 1s linear background-color;
          box-shadow: inset rgba(0, 0, 0, 0.1) 0 0 10px;
          position: relative;
          background: #aaa;
          border-radius: inherit;
          height: 18px;
        }
      }
    }

    p{
      font-size: 13px;
      margin:  10px 0 30px;
    }
    .ti{
      font-weight: 600;
      font-size: 18px;
      display: flex;
      i{
        margin-right: 5px;
        background: url("./img/0.png");
        width: 22px;
        height: 24px;
      }
    }
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 71px auto;
    width: 1195px;
    background: #fff;
    padding: 20px 30px;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, .1) 1px 3px 8px;
  }
}
