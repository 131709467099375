.incPage{
  .bb{
    height: 168px;
    display: flex;
    flex: 1;
    label{
      color: #aaa;
      line-height: 3;
    }
    span{
      font-size: 20px;
      font-weight: 200;
      color: #000;
    }
    &>div{
      flex: 1;
      height: 100%;
      &+div{
        position: relative;
        &:before{
          content: '';
          position: absolute;
          width: 1px;
          left: -1px;
          top: 30%;
          bottom: 30%;
          background: #ededed;
          display: block;
        }
      }
      &>div{
        height: 50%;
        padding-left: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
  .crd{
    position: relative;
    width: 320px;
    height: 181px;
    border-radius: 13px;
    color: #fff;
    background: linear-gradient(270deg, #FF6F93, #FF97B5);
    box-shadow: 5px 12px 24px -14px #ff8cac;
    display: flex;
    align-items: center;
    h3{
      position: absolute;
      top: 15px;
      left: 30px;
      font-size: 16px;
      font-weight: 200;
      opacity: .8;
    }
    label{
      font-size: 16px;
      opacity: .7;
    }
    h1{
      font-size: 32px;
      font-weight: 200;
    }
    &>span{
      position: absolute;
      bottom: 15px;
      right: 15px;
      color: #B64360;
      cursor: pointer;
      &:hover{
        text-decoration: underline;
      }
    }
    &>div{
      flex: 1;
      label{
        display: block;
      }
    }
    i{
      background: url("./img/a.png");
      width: 57px;
      height: 63px;
      opacity: .5;
      margin: 20px;
    }
   &.gr{
     background: linear-gradient(270deg, #5AC798, #87E2BE);
     box-shadow: 5px 12px 24px -14px #83dfba;
     i{
       margin-top: 35px;
     }
     &>span{
       color: #3E8868;
     }
   }
  }
 .x-st-h{
    padding: 0;
 }
  .x-st-ti{
    margin: 0;
  }
  .btn{
    width: 80px;
    height: 33px;
    margin-left: 10px;
  }
  .x-sc{
    .r{
      justify-content: flex-end;
    }
  }
  .x-tba{
    position: relative;
    .t{
      position: absolute;
      right: 20px;
      bottom: 15px;
      opacity: .4;
    }
  }
  .x-ss{
    .x-tba+.x-tba{
      margin-top: 20px;
    }
  }
}
